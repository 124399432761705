export default {
    "de": {
        "INSURANCE_FLAT": "Administrationspauschale",
        "lessons": "Fahrlektionen",
        "vku": "VKU Kurse",
        "insurance": "Versicherung",
        "app": "Theorie App",
        "voice_status_start": "Start",
        "voice_status_end": "Beendet",
        "voice_status_queue": "Warteschlange",
        "voice_status_taken": "im Gespräch",
        "voice_status_missed": "Verpasst",
        "voice_status_initiated": "Willkommen",
        "voice_status_out-of-office": "Out-of-Office",
        "voice_status_voicemail": "Sprachnachricht",
        "absences": "Absenzes",
        "exams": "Prüfungen",
        "students": "Schüler",
        "locations": "Standorte",
        "comments": "Kommentare",
        "employment": "Anstellung",
        "course_documents": "Kursdokumente",
        "course_manuals": "Kursanleitungen",
        "All": "Alle",
        "Instructors": "Kursleiter:innen",
        "Kursleiterinnen": "Kursleiter:innen",
        "Teachers": "Fahrlehrer:innen",
        "The {attribute} must contain at least one letter.": "{attribute} muss aus mindestens einem Zeichen bestehen.",
        "The {attribute} must contain at least one number.": "{attribute} muss aus mindestens einer Zahl bestehen.",
        "The {attribute} must contain at least one symbol.": "{attribute} muss aus mindestens einem Sonderzeichen bestehen.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "{attribute} muss aus mindestens einem Gross- und einem Kleinbuchstaben bestehen.",
        "{attribute} is keine gültige Sprache.": "{attribute} ist keine gültige Sprache.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Das {attribute} ist bereits in einem Datenleck aufgetaucht. Bitte wähle ein anderes {attribute}.",
        "Anmelden": "Anmelden",
        "E-Mail": "E-Mail",
        "Passwort": "Passwort",
        "Passwort vergessen?": "Passwort vergessen?",
        "Eine Woche lang angemeldet bleiben": "Eine Woche lang angemeldet bleiben",
        "Willkommen bei": "Willkommen bei",
        "Passwort zurücksetzen": "Passwort zurücksetzen",
        "Sie bekommen von uns einen Link per E-Mail zugesendet, um Ihr Passwort zurückzusetzen. Bitte geben Sie Ihre E-Mail-Adresse ein, die Sie zum Login bei My Sanio verwenden.": "Sie bekommen von uns einen Link per E-Mail zugesendet, um Ihr Passwort zurückzusetzen. Bitte geben Sie Ihre E-Mail-Adresse ein, die Sie zum Login bei My Sanio verwenden.",
        "Zurück zur Anmeldung": "Zurück zur Anmeldung",
        "Link anfordern": "Link anfordern",
        "zurück": "zurück",
        "Liste exportieren": "Liste exportieren",
        "Anmeldungen": "Anmeldungen",
        "Abmeldungen": "Abmeldungen",
        "Vorname": "Vorname",
        "Nachname": "Nachname",
        "Kurssprache": "Kurssprache",
        "Beschreibung": "Beschreibung",
        "Link ist aktiv": "Link ist aktiv",
        "Kurs freigeben": "Kurs freigeben",
        "Es existieren keine Daten.": "Es existieren keine Daten.",
        "Willkommen": "Willkommen",
        "Teilnehmende": "Teilnehmende",
        "Sortieren nach": "Sortieren nach",
        "Datum": "Datum",
        "Adresse": "Adresse",
        "Gesamte Liste freigeben": "Gesamte Liste freigeben",
        "Kursgruppe freigeben": "Kursgruppe freigeben",
        "Plätze frei": "Plätze frei",
        "ausgebucht": "ausgebucht",
        "Vielen Dank! Wir haben Ihre Angaben erhalten.": "Vielen Dank! Wir haben Ihre Angaben erhalten.",
        "Weitere Person anmelden": "Weitere Person anmelden",
        "An-/Abmeldung": "An-/Abmeldung",
        "Ja, ich nehme teil": "Ja, ich nehme teil",
        "Nein, ich bin verhindert": "Nein, ich bin verhindert",
        "Absenden": "Absenden",
        "Sie müssen die AGB akzeptieren!": "Sie müssen die AGB akzeptieren!",
        "Fragen oder Probleme?": "Fragen oder Probleme?",
        "Ihre Ansprechperson:": "Ihre Ansprechperson:",
        "Ihre Ansprechpersonen:": "Ihre Ansprechpersonen:",
        "Hallo": "Hallo",
        "Anmeldebestätigung": "Anmeldebestätigung",
        "Erste Hilfe Kurs": "Erste Hilfe Kurs",
        "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos": "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos",
        "Liebe Grüsse": "Liebe Grüsse",
        "Dein Sanio-Team": "Dein Sanio-Team",
        "Copyright": "Copyright",
        "Umbuchungsbestätigung": "Umbuchungsbestätigung",
        "Terminerinnerung": "Terminerinnerung",
        "Mitarbeitende": "Mitarbeitende",
        "Deutsch": "Deutsch",
        "Französisch": "Französisch",
        "Italienisch": "Italienisch",
        "Englisch": "Englisch",
        "Spanisch": "Spanisch",
        "Portugiesisch": "Portugiesisch",
        "In Kurs verschieben": "In Kurs verschieben",
        "Abbrechen": "Abbrechen",
        "Abmelden": "Abmelden",
        "{amount} Teilnehmende": "{amount} Teilnehmende",
        "Kurse": "Kurse",
        "Impersonation beenden": "Impersonation beenden",
        "Die Datei {fileName} wurde {statusHint} importiert.": "Die Datei {fileName} wurde {statusHint} importiert.",
        "teilweise": "teilweise",
        "erfolgreich": "erfolgreich",
        "Zeile": "Zeile",
        "Spalte": "Spalte",
        "Fehlermeldung": "Fehlermeldung",
        "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:": "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:",
        "Kontaktdaten": "Kontaktdaten",
        "Ähnliche TN": "Ähnliche TN",
        "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.": "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.",
        "Teilnehmerübersicht öffnen": "Teilnehmerübersicht öffnen",
        "Falls Sie Excel für den Import verwenden, achten Sie darauf das Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "Falls Sie Excel für den Import verwenden, achten Sie darauf das Spalten wie z.B. das Geburtsdatum als Text formatiert sind.",
        "Email": "E-Mail",
        "Handynummer": "Handynummer",
        "certificates.not-yet-known": "Noch nicht bekannt",
        "certificates.sanio": "Kursbestätigung Sanio",
        "certificates.src": "SRC Zertifikat",
        "Kursübersicht": "Kursübersicht",
        "Zurück zum Login": "Zurück zum Login",
        "Passwort bestätigen": "Passwort bestätigen",
        "Dashboard": "Dashboard",
        "In welchen Kurs soll {participantName} verschoben werden?": "In welchen Kurs soll {participantName} verschoben werden?",
        "Es gibt keine anderen Kurse.": "Es gibt keine anderen Kurse.",
        "Mehr anzeigen": "Mehr anzeigen",
        "{participantName} in Kurs {courseName} verschieben?": "{participantName} in Kurs {courseName} verschieben?",
        "Zum Kurs": "Zum Kurs",
        "Umbuchungsbestätigung senden": "Umbuchungsbestätigung senden",
        "Zurück": "Zurück",
        "Verschieben": "Verschieben",
        "Willkommen {name} 👋": "Willkommen {name} 👋",
        "Diese Anmeldemaske wurde deaktiviert.": "Diese Anmeldemaske wurde deaktiviert.",
        "errors.4xx.title": "Unerwarteter Fehler",
        "errors.4xx.head": "Uups...",
        "errors.4xx.hint": "Ein Fehler ist aufgetreten. Ihre Anfrage konnte nicht verarbeitet werden. Bitte überprüfen Sie Ihre Eingaben oder versuchen Sie es später erneut. Der Fehler wurde uns gemeldet.",
        "errors.5xx.title": "Interner Serverfehler",
        "errors.5xx.head": "Ou ou...",
        "errors.5xx.hint": "Ein unerwarteter Fehler ist aufgetreten. Gehen Sie zurück zur Startseite oder versuchen Sie es später erneut. Der Fehler wurde uns gemeldet.",
        "errors.401.title": "Zugriff verweigert",
        "errors.401.head": "Oops…",
        "errors.401.hint": "Sie sind nicht berechtigt, auf diese Seite zuzugreifen. Bitte melden Sie sich an und versuchen Sie es erneut.",
        "errors.403.title": "Zugriff verweigert",
        "errors.403.head": "Oops…",
        "errors.403.hint": "Zugriff verweigert. Sie haben keine Berechtigung, diese Seite zu besuchen. Bitte wenden Sie sich an uns, wenn Sie glauben, dass dies ein Fehler ist.",
        "errors.404.title": "Seite nicht gefunden",
        "errors.404.head": "Uups...",
        "errors.404.hint": "Die von Ihnen aufgerufene Seite existiert nicht. Gehen Sie zurück oder klicken Sie auf den Button um auf die Startseite zu gelangen.",
        "errors.500.title": "Interner Serverfehler",
        "errors.500.head": "Ou ou...",
        "errors.500.hint": "Ein unerwarteter Fehler ist aufgetreten. Gehen Sie zurück zur Startseite oder versuchen Sie es später erneut. Der Fehler wurde uns gemeldet.",
        "errors.503.title": "Wartungsmodus",
        "errors.503.head": "Einen Moment bitte...",
        "errors.503.hint": "Wir führen derzeit Wartungsarbeiten durch, um Verbesserungen und Fehlerbehebungen für eine bessere Nutzererfahrung vorzunehmen. Bitte versuchen Sie es in Kürze erneut. Vielen Dank für Ihr Verständnis.",
        "errors.503.hint2": "In dringenden Fällen erreichen Sie uns per E-Mail unter <a href=\"mailto:mail@sanio.ch\">mail@sanio.ch</a> oder telefonisch unter <a href=\"tel:+41315212409\">031 521 24 09</a>.",
        "errors.504.title": "Timeout",
        "errors.504.head": "Timeout…",
        "errors.504.hint": "Die Anfrage hat zu lange gedauert. Bitte versuchen Sie es später erneut. Der Fehler wurde uns gemeldet.",
        "errors.back-to-start": "Zur Startseite",
        "Fehler beim Laden der Kurse": "Fehler beim Laden der Kurse",
        "Du wurdest in den Kurs «{courseName}» umgebucht.": "Du wurdest in den Kurs «{courseName}» umgebucht.",
        "Die aktuellen Infos lauten:": "Hier die neuen Infos:",
        "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:": "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:",
        "oder": "oder",
        "Kursort:": "Kursort:",
        "Kursdaten": "Kursdaten",
        "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:": "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:",
        "Bald ist es soweit und der Kurs «{courseName}» findet statt.": "Bald ist es soweit und der Kurs «{courseName}» findet statt.",
        "Hier nochmals die wichtigsten Infos:": "Hier nochmals die wichtigsten Infos:",
        "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉": "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉",
        "Kurs-ID": "Kurs-ID",
        "Zum Kalender hinzufügen": "Zum Kalender hinzufügen",
        "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.": "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.",
        "Die E-Mail-Adresse ist ungültig.": "Die E-Mail-Adresse ist ungültig",
        "Die Handynummer ist ungültig.": "Die Handynummer ist ungültig.",
        "Mit dieser E-Mail kannst du dich leider nicht anmelden.": "Mit dieser E-Mail kannst du dich leider nicht anmelden.",
        "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.": "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.",
        "Der Code ist ungültig.": "Der Code ist ungültig.",
        "Sie müssen die AGBs akzeptieren um fortfahren zu können.": "Sie müssen die AGB akzeptieren um fortfahren zu können.",
        "Bitte geben Sie eine gültige Handynummer ein.": "Bitte geben Sie eine gültige Handynummer ein.",
        "Text wurde in die Zwischenablage kopiert": "Text wurde in die Zwischenablage kopiert",
        "Text konnte nicht in die Zwischenablage kopiert werden": "Text konnte nicht in die Zwischenablage kopiert werden",
        "Der Katalog wurde erstellt.": "Der Katalog wurde erstellt.",
        "Der Katalog wurde aktualisiert.": "Der Katalog wurde aktualisiert.",
        "Der Katalog wurde entfernt.": "Der Katalog wurde entfernt.",
        "Das Thema wurde erstellt.": "Das Thema wurde erstellt.",
        "Das Thema wurde aktualisiert.": "Das Thema wurde aktualisiert.",
        "Das Thema wurde entfernt.": "Das Thema wurde entfernt.",
        "Der Kurs wurde dupliziert 🎉": "Der Kurs wurde dupliziert 🎉",
        "Kurs": "Kurs",
        "Story wurde erfolgreich kopiert 🎉": "Story wurde erfolgreich kopiert 🎉",
        "Eine Variante|{count} Varianten": "Eine Variante|{count} Varianten",
        "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.": "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.",
        "Geschlecht": "Geschlecht",
        "Männlich": "Männlich",
        "Weiblich": "Weiblich",
        "Divers": "Divers",
        "Projektstandort": "Projektstandort",
        "Wohnkanton": "Wohnkanton",
        "Sanio Logo": "Sanio Logo",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.",
        "hier": "hier",
        "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.": "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.",
        "Dein Sanio Team": "Dein Sanio Team",
        "Hallo {name} 👋": "Hallo {name} 👋",
        "Hier sind nochmals die wichtigsten Details:": "Hier sind nochmals die wichtigsten Details:",
        "Teilnehmer:innen": "Teilnehmer:innen",
        "Name": "Name",
        "Letzter Kurs": "Letzter Kurs",
        "Nächster Kurs": "Nächster Kurs",
        "Hinzufügen": "Hinzufügen",
        "Exportieren": "Exportieren",
        "Zu Kurs zugeordnet": "Zu Kurs zugeordnet",
        "Keinem Kurs zugeordnet": "Keinem Kurs zugeordnet",
        "Suchbegriff": "Suchbegriff",
        "Alle": "Alle",
        "Ungruppierte Kurse": "Ungruppierte Kurse",
        "Es wurden mehrere Konten für diese Angaben gefunden. Bitte wähle den gewünschten Kunden aus.": "Es wurden mehrere Konten für diese Angaben gefunden. Bitte wähle den gewünschten Kunden aus.",
        "Anmeldebestätigung erneut zustellen": "Anmeldebestätigung erneut zustellen",
        "Einladung zum eLearning": "Einladung zum eLearning",
        "Wir freuen uns, dir mitzuteilen, dass das eLearning für dich nun bereitsteht! Du kannst ab sofort mit dem Lernen beginnen. 🎉": "Wir freuen uns, dir mitzuteilen, dass das eLearning für dich nun bereitsteht! Du kannst ab sofort mit dem Lernen beginnen. 🎉",
        "Bis zum <strong>{courseName}</strong> am <strong>{courseStart}</strong> in <strong>{coursePlace}</strong> musst du das eLearning unbedingt abgeschlossen haben. Es dient als Grundlage für den praktischen Kurs.": "Bis zum <strong>{courseName}</strong> am <strong>{courseStart}</strong> in <strong>{coursePlace}</strong> musst du das eLearning unbedingt abgeschlossen haben. Es dient als Grundlage für den praktischen Kurs.",
        "Das eLearning dauert insgesamt etwa 2 Stunden.": "Das eLearning dauert insgesamt etwa 2 Stunden.",
        "Du kannst das eLearning entweder in einem Durchgang oder in mehreren Etappen absolvieren.": "Du kannst das eLearning entweder in einem Durchgang oder in mehreren Etappen absolvieren.",
        "Am Ende gibt es eine kurze Lernkontrolle. Daher lohnt es sich, die Inhalte aufmerksam durchzuarbeiten!": "Am Ende gibt es eine kurze Lernkontrolle. Daher lohnt es sich, die Inhalte aufmerksam durchzuarbeiten!",
        "Teilnehmer:in abmelden / aus Kurs entfernen": "Teilnehmer:in abmelden / aus Kurs entfernen",
        "Möchtest du den/die Teilnehmer:in wirklich vom Kurs abmelden / aus dem Kurs entfernen?": "Möchtest du den/die Teilnehmer:in wirklich vom Kurs abmelden / aus dem Kurs entfernen?",
        "Abmelden / entfernen": "Abmelden / entfernen",
        "eLearning Einladung verschicken": "eLearning Einladung verschicken",
        "Willst du wirklich eine eLearning Einladung verschicken?": "Willst du wirklich eine eLearning Einladung verschicken?",
        "Einladung verschicken": "Einladung verschicken",
        "Willst du wirklich die Anmeldebestätigung erneut zustellen?": "Willst du wirklich die Anmeldebestätigung erneut zustellen?",
        "Bestätigung erneut verschicken": "Bestätigung erneut verschicken",
        "Teilnehmer:in abmelden": "Teilnehmer:in abmelden",
        "Bemerkungen": "Bemerkungen",
        "Wert fehlt!": "Wert fehlt!",
        "{0} Es wurden keine Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.|{1} Es wurde ein:e Teilnehmer:in angelegt und {totalUpdated} aktualisiert.|[2,*] Es wurden {count} Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.": "{0} Es wurden keine Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.|{1} Es wurde ein:e Teilnehmer:in angelegt und {totalUpdated} aktualisiert.|[2,*] Es wurden {count} Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.",
        "Zu Kursgruppe hinzufügen": "Zu Kursgruppe hinzufügen",
        "Neue Kursgruppe erstellen": "Neue Kursgruppe erstellen",
        "Möchtest du den Kurs wirklich endgültig löschen? Diese Aktion kann nicht rückgängig gemacht werden.": "Möchtest du den Kurs wirklich endgültig löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
        "Möchtest du den Kurs wirklich archivieren? Der Kurs ist danach nicht mehr sichtbar, kann aber durch einen Admin wiederhergestellt werden.": "Möchtest du den Kurs wirklich archivieren? Der Kurs ist danach nicht mehr sichtbar, kann aber durch einen Admin wiederhergestellt werden.",
        "Freigabelink löschen": "Freigabelink löschen",
        "Möchtest du den Freigabelink wirklich löschen?": "Möchtest du den Freigabelink wirklich löschen?",
        "Archivieren": "Archivieren",
        "Endgültig löschen": "Endgültig löschen",
        "Gruppe löschen": "Gruppe löschen",
        "Möchtest du diese Gruppe wirklich löschen?": "Möchtest du diese Gruppe wirklich löschen?",
        "Kurs aus Gruppe entfernen": "Kurs aus Gruppe entfernen",
        "Kurs duplizieren": "Kurs duplizieren",
        "Kurs archivieren": "Kurs archivieren",
        "Kurs löschen": "Kurs löschen",
        "Der Freigabelink wurde in die Zwischenablage kopiert! 🎉": "Der Freigabelink wurde in die Zwischenablage kopiert! 🎉",
        "Der Freigabelink wurde gelöscht! 🎉": "Der Freigabelink wurde gelöscht! 🎉",
        "Link teilen": "Link teilen",
        "Gruppe bearbeiten": "Gruppe bearbeiten",
        "Neue:n Teilnehmer:in anmelden": "Neue:n Teilnehmer:in anmelden",
        "nimmt teil": "nimmt teil",
        "Speichern": "Speichern",
        "Ähnliche Teilnehmer:innen": "Ähnliche Teilnehmer:innen",
        "Ein unerwarteter Fehler ist aufgetreten": "Ein unerwarteter Fehler ist aufgetreten",
        "Bereits in diesem Kurs vorhanden": "Bereits in diesem Kurs vorhanden",
        "Es wurden keine ähnlichen Teilnehmer:innen gefunden.": "Es wurden keine ähnlichen Teilnehmer:innen gefunden.",
        "Fülle das Formular aus und die Suche wird gestartet.": "Fülle das Formular aus und die Suche wird gestartet.",
        "Suche ähnliche Teilnehmer:innen...": "Suche ähnliche Teilnehmer:innen...",
        "eLearning nicht gefunden": "eLearning nicht gefunden",
        "Wie hat dir das eLearning allgemein gefallen?": "Wie hat dir das eLearning allgemein gefallen?",
        "🤩 Sehr gut!": "🤩 Sehr gut!",
        "😊 Gut": "😊 Gut",
        "😐 Ok": "😐 Ok",
        "😕 Nicht so gut": "😕 Nicht so gut",
        "😩 Schlecht": "😩 Schlecht",
        "Bitte wähle eine Antwort aus.": "Bitte wähle eine Antwort aus.",
        "Wie verständlich waren für dich die Lerninhalte?": "Wie verständlich waren für dich die Lerninhalte?",
        "🤩 Sehr verständlich!": "🤩 Sehr verständlich!",
        "😊 Verständlich": "😊 Verständlich",
        "😕 Nicht so verständlich": "😕 Nicht so verständlich",
        "😩 Gar nicht verständlich": "😩 Gar nicht verständlich",
        "Wie waren die Aufgaben in den Notfällen 1–8 für dich?": "Wie waren die Aufgaben in den Notfällen 1–8 für dich?",
        "🤩 Sehr einfach!": "🤩 Sehr einfach!",
        "😊 einfach": "😊 einfach",
        "😐 Nicht so einfach": "😐 Nicht so einfach",
        "😕 Schwierig": "😕 Schwierig",
        "😩 Sehr schwierig": "😩 Sehr schwierig",
        "Wie beurteilst du die Benutzerfreundlichkeit des eLearnings?": "Wie beurteilst du die Benutzerfreundlichkeit des eLearnings?",
        "🤩 Super einfache Bedienung!": "🤩 Super einfache Bedienung!",
        "😊 Einfach zu bedienen": "😊 Einfach zu bedienen",
        "😐 Teilweise etwas mühsam": "😐 Teilweise etwas mühsam",
        "😩 Richtig mühsame Bedienung": "😩 Richtig mühsame Bedienung",
        "Wie schwierig war für dich die Lernkontrolle am Ende?": "Wie schwierig war für dich die Lernkontrolle am Ende?",
        "Warum war die Lernkontrolle für dich einfach?": "Warum war die Lernkontrolle für dich einfach?",
        "Überspringen": "Überspringen",
        "Warum war die Lernkontrolle für dich schwierig?": "Warum war die Lernkontrolle für dich schwierig?",
        "Wie können wir das eLearning verbessern? Was hat dir gefehlt?": "Wie können wir das eLearning verbessern? Was hat dir gefehlt?",
        "Würdest du das eLearning weiterempfehlen?": "Würdest du das eLearning weiterempfehlen?",
        "🤩 Ja klar!": "🤩 Ja klar!",
        "😊 Ja, wahrscheinlich schon": "😊 Ja, wahrscheinlich schon",
        "😐 Weiss nicht recht": "😐 Weiss nicht recht",
        "😕 Eher nicht": "😕 Eher nicht",
        "😩 Nein, auf keinen Fall": "😩 Nein, auf keinen Fall",
        "Vielen Dank für deine Antworten!": "Vielen Dank für deine Antworten!",
        "Cool hast du dir die Zeit genommen!": "Cool hast du dir die Zeit genommen!",
        "Fehler beim Laden der Suchergebnisse": "Fehler beim Laden der Suchergebnisse",
        "Jetzt mit dem eLearning starten": "Jetzt mit dem eLearning starten",
        "Es wird Zeit, mit dem eLearning zu beginnen! Bitte schliesse es bis zum Kursbeginn am <strong>{courseStart}</strong>, ab, da es die wichtige Grundlage für unseren Kurs bildet. Plane dafür etwa zwei Stunden ein, damit du gut vorbereitet bist und das Beste aus dem Kurs mitnehmen kannst. Wir freuen uns auf dich!": "Es wird Zeit, mit dem eLearning zu beginnen! Bitte schliesse es bis zum Kursbeginn am <strong>{courseStart}</strong>, ab, da es die wichtige Grundlage für unseren Kurs bildet. Plane dafür etwa zwei Stunden ein, damit du gut vorbereitet bist und das Beste aus dem Kurs mitnehmen kannst. Wir freuen uns auf dich!",
        "Dein Anmeldecode für dein my Sanio Konto lautet: <strong>{code}</strong>": "Dein Anmeldecode für dein my Sanio Konto lautet: <strong>{code}</strong>",
        "Teilnehmer:innen {company}": "Teilnehmer:innen {company}",
        "{firstname}, eLearning für den Kurs von {company} nicht vergessen!": "{firstname}, eLearning für den Kurs von {company} nicht vergessen!",
        "Dein my.sanio Verifizierungscode": "Dein my.sanio Verifizierungscode",
        "allgemeinen Geschäftsbedingungen": "allgemeinen Geschäftsbedingungen",
        "Datenschutzerklärung": "Datenschutzerklärung",
        "Ich akzeptiere die {tos} und habe die {privacyPolicy} gelesen und verstanden.": "Ich akzeptiere die {tos} und habe die {privacyPolicy} gelesen und verstanden.",
        "AGBs und Geschäftsbedingen müssen akzeptiert werden.": "AGB und Datenschutz müssen akzeptiert werden.",
        "Der Kurs ist leider bereits ausgebucht.": "Der Kurs ist leider bereits ausgebucht.",
        "🚫 Wir konnten dich leider nicht am Kurs anmelden.": "🚫 Wir konnten dich leider nicht am Kurs anmelden.",
        "Persönliche Daten": "Persönliche Daten",
        "Profil": "Profil",
        "Zukünftige Kurse": "Zukünftige Kurse",
        "Vergangene Kurse": "Vergangene Kurse",
        "eLearning": "eLearning",
        "Fortschritt:": "Fortschritt:",
        "Dauer:": "Dauer:",
        "Kommunikation": "Kommunikation",
        "unbekannter Betreff": "unbekannter Betreff",
        "Zeit": "Zeit",
        "Status": "Status",
        "Kanal": "Kanal",
        "Inhalt": "Inhalt",
        "Von:": "Von:",
        "An:": "An:",
        "CC:": "CC:",
        "BCC:": "BCC:",
        "Antwort an:": "Antwort an:",
        "Anhänge": "Anhänge",
        "Nicht dargestellte Kursdaten": "Nicht dargestellte Kursdaten",
        "Benutzerbild": "Benutzerbild",
        "Meine Kurse": "Meine Kurse",
        "Dashboard Instruktor:innen": "Dashboard Instruktor:innen",
        "Wenn wir für diese E-Mail Adresse ein Konto gefunden haben, erhältst du in Kürze eine E-Mail mit einem Link zum Einloggen (Bitte überprüfe auch deinen Spam-Ordner).": "Wenn wir für diese E-Mail Adresse ein Konto gefunden haben, erhältst du in Kürze eine E-Mail mit einem Link zum Einloggen (Bitte überprüfe auch deinen Spam-Ordner).",
        "Hier ist dein Link zum Einloggen:": "Hier ist dein Link zum Einloggen:",
        "Jetzt einloggen": "Jetzt einloggen",
        "Link zum Einloggen": "Link zum Einloggen",
        "Teilnahme-Status erfolgreich aktualisiert.": "Teilnahme-Status erfolgreich aktualisiert.",
        "Team": "Team",
        "Anwesenheit": "Anwesenheit",
        "Gemäss Kursleiter:in anwesend": "Gemäss Kursleiter:in anwesend",
        "Gemäss Kursleiter:in abwesend": "Gemäss Kursleiter:in abwesend",
        "🧘 Du hast diese Woche keine Kurse.": "🧘 Du hast diese Woche keine Kurse.",
        "🌴 Du hast keine zukünftigen Kurse.": "🌴 Du hast keine zukünftigen Kurse.",
        "😌 Du hast keine vergangenen Kurse.": "😌 Du hast keine vergangenen Kurse.",
        "Diese Woche": "Diese Woche",
        "messages": {
            "feedback": {
                "moods": {
                    "positive": "Positiv",
                    "neutral": "Neutral",
                    "negative": "Negativ"
                }
            },
            "communication": {
                "notifications": {
                    "channel": {
                        "mail": "E-Mail",
                        "database": "Datenbank",
                        "twilio": "SMS"
                    },
                    "status": {
                        "sent": "Gesendet",
                        "sending": "Wird versendet...",
                        "skipped": "Übersprungen",
                        "failed": "Fehlgeschlagen"
                    }
                }
            }
        }
    },
    "en": {
        "Anmelden": "Log in",
        "E-Mail": "Email",
        "Passwort": "Password",
        "Passwort vergessen?": "Forgot your password?",
        "Eine Woche lang angemeldet bleiben": "Stay signed in for a week",
        "Willkommen bei": "Welcome to",
        "Passwort zurücksetzen": "Reset password",
        "Sie bekommen von uns einen Link per E-Mail zugesendet, um Ihr Passwort zurückzusetzen. Bitte geben Sie Ihre E-Mail-Adresse ein, die Sie zum Login bei My Sanio verwenden.": "You'll receive a link by email to reset your password. Please enter the email address you used to sign in to My Sanio.",
        "Zurück zur Anmeldung": "Return to sign in",
        "Link anfordern": "Request link",
        "zurück": "back",
        "Liste exportieren": "Export list",
        "Anmeldungen": "Registrations",
        "Abmeldungen": "Cancellations",
        "Vorname": "First name",
        "Nachname": "Last name",
        "Kurssprache": "Course language",
        "Beschreibung": "Description",
        "Link ist aktiv": "Link is active",
        "Kurs freigeben": "Share course",
        "Es existieren keine Daten.": "No existing data.",
        "Willkommen": "Welcome",
        "Teilnehmende": "Participants",
        "Sortieren nach": "Sort by",
        "Datum": "Date",
        "Adresse": "Address",
        "Gesamte Liste freigeben": "Share entire list",
        "Kursgruppe freigeben": "Share course group",
        "Plätze frei": "Remaining slots",
        "ausgebucht": "fully booked",
        "Vielen Dank! Wir haben Ihre Angaben erhalten.": "Thank you very much! We have received your details.",
        "Weitere Person anmelden": "Register additional person",
        "An-/Abmeldung": "Registration/cancellation",
        "Ja, ich nehme teil": "Yes, I will participate",
        "Nein, ich bin verhindert": "No, I'm unable to attend",
        "Ich akzeptiere die": "I accept the",
        "allgemeinen Geschäftsbedingungen": "Terms and Conditions",
        "Die": "The",
        "Datenschutzerklärung": "Privacy policy",
        "habe ich gelesen und verstanden.": "I have read and understood.",
        "Absenden": "Send",
        "Sie müssen die AGB akzeptieren!": "You must accept the terms!",
        "Fragen oder Probleme?": "Questions or problems?",
        "Ihre Ansprechperson:": "Your contact person:",
        "Geburtsdatum": "Date of birth",
        "Geburtsdatum (TT.MM.JJJJ)": "Date of birth (dd.mm.yyyy)",
        "Hallo": "Hello",
        "Anmeldebestätigung": "Registration Confirmation",
        "Erste Hilfe Kurs": "First Aid Course",
        "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos": "Thank you for your registration for the First Aid Course. Here are the most important details again",
        "Liebe Grüsse": "Best regards",
        "Dein Sanio-Team": "Your Sanio Team",
        "Copyright": "Copyright",
        "Umbuchungsbestätigung": "Booking confirmation",
        "Terminerinnerung": "Appointment reminder",
        "Bald ist es soweit und der Erste Hilfe Kurs findet statt. Hier nochmals die wichtigsten Infos:": "The time has come, and the first aid course is taking place soon. Here are the most important details again:",
        "Zurück zum Login": "Back to login",
        "Passwort bestätigen": "Confirm password",
        "Mitarbeiter:innen": "Employees",
        "Deutsch": "German",
        "Französisch": "French",
        "Italienisch": "Italian",
        "Englisch": "English",
        "Spanisch": "Spanish",
        "Portugiesisch": "Portuguese",
        "Kurse": "Courses",
        "Impersonation beenden": "Stop impersonation",
        "INSURANCE_FLAT": "Administration fee",
        "lessons": "Driving lessons",
        "Teachers": "Driving instructors",
        "In Kurs verschieben": "Change course",
        "Abbrechen": "Cancel",
        "Abmelden": "Log out",
        "Kursleiterinnen": "Instructors",
        "absences": "Absences",
        "All": "All",
        "app": "Theory app",
        "certificates.not-yet-known": "Not yet known",
        "certificates.sanio": "Course confirmation Sanio",
        "certificates.src": "SRC certificate",
        "comments": "Comments",
        "course_documents": "Course documents",
        "course_manuals": "Course instructions",
        "Die Datei {fileName} wurde {statusHint} importiert.": "The file {fileName} was imported {statusHint}.",
        "employment": "Employment",
        "erfolgreich": "Successful",
        "exams": "Examinations",
        "Falls Sie Excel für den Import verwenden, achten Sie darauf das Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "If you use Excel for the import, make sure that columns such as the date of birth are formatted as text.",
        "Fehlermeldung": "Error message",
        "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:": "The following lines could not be imported due to invalid (<span class=\"text-red\">marked in red</span>) data:",
        "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.": "The following lines were nevertheless imported due to unclear assignment.",
        "Handynummer": "Cell phone number",
        "Instructors": "Instructors",
        "insurance": "Insurance",
        "Kontaktdaten": "Contact details",
        "locations": "Locations",
        "Mitarbeitende": "Employees",
        "Spalte": "Column",
        "students": "Pupils",
        "Teilnehmerübersicht öffnen": "Open participant overview",
        "teilweise": "Partly",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The {attribute} has already appeared in a data leak. Please choose another {attribute}.",
        "The {attribute} must contain at least one letter.": "{attribute} must consist of at least one character.",
        "The {attribute} must contain at least one number.": "{attribute} must consist of at least one number.",
        "The {attribute} must contain at least one symbol.": "{attribute} must consist of at least one special character.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "{attribute} must consist of at least one uppercase and one lowercase letter.",
        "vku": "VKU courses",
        "voice_status_end": "Finished",
        "voice_status_missed": "Missed",
        "voice_status_out-of-office": "Out-of-Office",
        "voice_status_queue": "Queue",
        "voice_status_start": "Start",
        "voice_status_taken": "in conversation",
        "voice_status_voicemail": "Voice message",
        "Zeile": "Line",
        "{amount} Teilnehmende": "{amount} Participants",
        "Ähnliche TN": "Similar TN",
        "Email": "Email",
        "voice_status_initiated": "Welcome",
        "Dashboard": "Dashboard",
        "Es gibt keine anderen Kurse.": "There are no other courses.",
        "Mehr anzeigen": "Show more",
        "{participantName} in Kurs {courseName} verschieben?": "Move {participantName} to course {courseName}?",
        "Zum Kurs": "To the course",
        "Umbuchungsbestätigung senden": "Send rebooking confirmation",
        "Zurück": "Back",
        "Verschieben": "Move",
        "Willkommen {name} 👋": "Welcome {name} 👋",
        "Kursübersicht": "Course overview",
        "In welchen Kurs soll {participantName} verschoben werden?": "Which course should {participantName} be moved to?",
        "Diese Anmeldemaske wurde deaktiviert.": "This login screen has been deactivated.",
        "errors.4xx.title": "Unexpected error",
        "errors.4xx.head": "Oops...",
        "errors.5xx.head": "Ou ou...",
        "errors.4xx.hint": "An error has occurred. Your request could not be processed. Please check your entries or try again later. The error has been reported to us.",
        "errors.5xx.title": "Internal server error",
        "errors.5xx.hint": "An unexpected error has occurred. Go back to the home page or try again later. The error has been reported to us.",
        "errors.401.title": "Access denied",
        "errors.401.head": "Oops…",
        "errors.401.hint": "You are not authorized to access this page. Please log in and try again.",
        "errors.403.title": "Access denied",
        "errors.403.head": "Oops…",
        "errors.403.hint": "Access denied. You do not have permission to visit this page. Please contact us if you believe this is an error.",
        "errors.404.title": "Page not found",
        "errors.404.head": "Oops...",
        "errors.404.hint": "The page you have called up does not exist. Go back or click on the button to return to the start page.",
        "errors.500.title": "Internal server error",
        "errors.500.head": "Ou ou...",
        "errors.500.hint": "An unexpected error has occurred. Go back to the home page or try again later. The error has been reported to us.",
        "errors.503.title": "Maintenance mode",
        "errors.503.head": "One moment please...",
        "errors.503.hint": "We are currently performing maintenance to make improvements and bug fixes for a better user experience. Please try again shortly. Thank you for your understanding.",
        "errors.503.hint2": "In urgent cases, you can reach us by e-mail at <a href=\"mailto:mail@sanio.ch\">mail@sanio.ch</a> or by telephone at <a href=\"tel:+41315212409\">031 521 24 09</a>.",
        "errors.504.title": "Timeout",
        "errors.504.head": "Timeout…",
        "errors.504.hint": "The request took too long. Please try again later. The error has been reported to us.",
        "errors.back-to-start": "To the homepage",
        "{attribute} is keine gültige Sprache.": "{attribute} is not a valid language.",
        "Fehler beim Laden der Kurse": "Error loading the courses",
        "oder": "or",
        "Zum Kalender hinzufügen": "Add to calendar",
        "Der Code ist ungültig.": "The code is invalid.",
        "Sie müssen die AGBs akzeptieren um fortfahren zu können.": "You must accept the terms and conditions to proceed.",
        "Bitte geben Sie eine gültige Handynummer ein.": "Please enter a valid cell phone number.",
        "Du wurdest in den Kurs «{courseName}» umgebucht.": "You have been rebooked into the course \"{courseName}\".",
        "Die aktuellen Infos lauten:": "Here is the new information:",
        "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:": "If you have any questions, please contact {name} directly:",
        "Kursort:": "Course location:",
        "Kursdaten": "Course dates",
        "Bald ist es soweit und der Kurs «{courseName}» findet statt.": "Soon the time will come and the course \"{courseName}\" will take place.",
        "Hier nochmals die wichtigsten Infos:": "Here is the most important information again:",
        "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉": "You have been successfully enrolled on the course \"{courseName}\" 🎉",
        "Kurs-ID": "Course ID",
        "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.": "No e-mail address or cell phone number was provided.",
        "Die E-Mail-Adresse ist ungültig.": "The e-mail address is invalid",
        "Die Handynummer ist ungültig.": "The cell phone number is invalid.",
        "Mit dieser E-Mail kannst du dich leider nicht anmelden.": "Unfortunately, you cannot register with this e-mail.",
        "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.": "Unfortunately, you cannot register with this telephone number.",
        "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:": "Thank you for your registration for the First Aid Course. Here are the most important details again:",
        "Text wurde in die Zwischenablage kopiert": "Text has been copied to the clipboard",
        "Text konnte nicht in die Zwischenablage kopiert werden": "Text could not be copied to the clipboard",
        "Der Katalog wurde erstellt.": "The catalog was created.",
        "Der Katalog wurde aktualisiert.": "The catalog has been updated.",
        "Der Katalog wurde entfernt.": "The catalog has been removed.",
        "Das Thema wurde erstellt.": "The topic was created.",
        "Das Thema wurde aktualisiert.": "The topic has been updated.",
        "Das Thema wurde entfernt.": "The topic has been removed.",
        "Der Kurs wurde dupliziert 🎉": "The course has been duplicated 🎉",
        "Kurs": "Course",
        "Story wurde erfolgreich kopiert 🎉": "Story was successfully copied 🎉",
        "Eine Variante|{count} Varianten": "One variant|{count} Variants",
        "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.": "The eLearning area is not available for this customer.",
        "Geschlecht": "Gender",
        "Männlich": "male",
        "Keinem Kurs zugeordnet": "Not assigned to a course",
        "Suchbegriff": "Search term",
        "Weiblich": "female",
        "Divers": "other",
        "Projektstandort": "Project location",
        "Wohnkanton": "Canton of residence",
        "Sanio Logo": "Sanio logo",
        "hier": "here",
        "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.": "Click here for our <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">general terms and conditions</a> and our <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">privacy policy</a>.",
        "Hallo {name} 👋": "Hello {name} 👋",
        "Hier sind nochmals die wichtigsten Details:": "Here are the most important details again:",
        "Teilnehmer:innen": "Participants",
        "Name": "Name",
        "Letzter Kurs": "Last course",
        "Nächster Kurs": "Next course",
        "Hinzufügen": "Add",
        "Exportieren": "Export",
        "Dein Sanio Team": "Your Sanio Team",
        "Alle": "All",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "If you no longer wish to receive emails from this list, you can unsubscribe at <a href=\"{unsubscribeListUrl}\" target=\"_blank\">here</a>.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Do you no longer wish to receive offers from us? Then you can unsubscribe at <a href=\"{unsubscribeUrl}\" target=\"_blank\">here</a>.",
        "Ungruppierte Kurse": "Ungrouped courses",
        "Es wurden mehrere Konten für diese Angaben gefunden. Bitte wähle den gewünschten Kunden aus.": "Several accounts were found for these credentials. Please select the desired customer.",
        "Anmeldebestätigung erneut zustellen": "Resend registration confirmation",
        "Einladung zum eLearning": "Invitation to eLearning",
        "Wir freuen uns, dir mitzuteilen, dass das eLearning für dich nun bereitsteht! Du kannst ab sofort mit dem Lernen beginnen. 🎉": "We are pleased to inform you that the eLearning is now available for you! You can start learning now. 🎉",
        "Bis zum <strong>{courseName}</strong> am <strong>{courseStart}</strong> in <strong>{coursePlace}</strong> musst du das eLearning unbedingt abgeschlossen haben. Es dient als Grundlage für den praktischen Kurs.": "You must complete the eLearning by the time of the <strong>{courseName}</strong> on <strong>{courseStart}</strong> at <strong>{coursePlace}</strong>. It serves as the foundation for the practical course.",
        "Das eLearning dauert insgesamt etwa 2 Stunden.": "The eLearning takes about 2 hours in total.",
        "Du kannst das eLearning entweder in einem Durchgang oder in mehreren Etappen absolvieren.": "You can complete the eLearning either in one go or in several stages.",
        "Am Ende gibt es eine kurze Lernkontrolle. Daher lohnt es sich, die Inhalte aufmerksam durchzuarbeiten!": "There is a short learning check at the end. It is therefore worth working through the content carefully!",
        "Teilnehmer:in abmelden / aus Kurs entfernen": "Participant:in unsubscribe / remove from course",
        "Möchtest du den/die Teilnehmer:in wirklich vom Kurs abmelden / aus dem Kurs entfernen?": "Do you really want to deregister / remove the participant from the course?",
        "Abmelden / entfernen": "Log out / remove",
        "eLearning Einladung verschicken": "Send eLearning invitation",
        "Willst du wirklich eine eLearning Einladung verschicken?": "Do you really want to send an eLearning invitation?",
        "Einladung verschicken": "Send invitation",
        "Willst du wirklich die Anmeldebestätigung erneut zustellen?": "Do you really want to resend the registration confirmation?",
        "Bestätigung erneut verschicken": "Send confirmation again",
        "Teilnehmer:in abmelden": "Unsubscribe Participant:in",
        "Bemerkungen": "Remarks",
        "Wert fehlt!": "missing value!",
        "Ihre Ansprechpersonen:": "Your contact persons:",
        "{0} Es wurden keine Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.|{1} Es wurde ein:e Teilnehmer:in angelegt und {totalUpdated} aktualisiert.|[2,*] Es wurden {count} Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.": "{0} No participants were created and {totalUpdated} was updated.|{1} One participant was created and {totalUpdated} was updated.|[2,*] {count} participants were created and {totalUpdated} was updated.",
        "Gruppe bearbeiten": "Edit group",
        "Neue:n Teilnehmer:in anmelden": "Register new participants",
        "Zu Kursgruppe hinzufügen": "Add course to group",
        "Neue Kursgruppe erstellen": "Create new course group",
        "Möchtest du den Kurs wirklich endgültig löschen? Diese Aktion kann nicht rückgängig gemacht werden.": "Do you really want to delete the course permanently? This action cannot be undone.",
        "Möchtest du den Kurs wirklich archivieren? Der Kurs ist danach nicht mehr sichtbar, kann aber durch einen Admin wiederhergestellt werden.": "Do you really want to archive the course? The course is then no longer visible, but can be restored by an admin.",
        "Freigabelink löschen": "Delete share link",
        "Möchtest du den Freigabelink wirklich löschen?": "Do you really want to delete the share link?",
        "Archivieren": "Archive",
        "Endgültig löschen": "Delete permanently",
        "Gruppe löschen": "Delete group",
        "Möchtest du diese Gruppe wirklich löschen?": "Do you really want to delete this group?",
        "Kurs aus Gruppe entfernen": "Remove course from group",
        "Kurs duplizieren": "Duplicate course",
        "Kurs archivieren": "Archive course",
        "Kurs löschen": "Delete course",
        "Der Freigabelink wurde in die Zwischenablage kopiert! 🎉": "The share link has been copied to the clipboard! 🎉",
        "Der Freigabelink wurde gelöscht! 🎉": "The share link has been deleted! 🎉",
        "Link teilen": "Share link",
        "nimmt teil": "participates",
        "Ähnliche Teilnehmer:innen": "Similar participants",
        "Ein unerwarteter Fehler ist aufgetreten": "An unexpected error has occurred",
        "Bereits in diesem Kurs vorhanden": "Already available in this course",
        "Es wurden keine ähnlichen Teilnehmer:innen gefunden.": "No similar participants were found.",
        "Fülle das Formular aus und die Suche wird gestartet.": "Fill out the form to start the search.",
        "Suche ähnliche Teilnehmer:innen...": "Looking for similar participants...",
        "eLearning nicht gefunden": "eLearning not found",
        "Wie hat dir das eLearning allgemein gefallen?": "How did you like the eLearning in general?",
        "🤩 Sehr gut!": "🤩 Very good!",
        "😊 Gut": "😊 Good",
        "😐 Ok": "😐 Ok",
        "😕 Nicht so gut": "😕 Not so good",
        "😩 Schlecht": "😩 Bad",
        "Bitte wähle eine Antwort aus.": "Please select an answer.",
        "Wie verständlich waren für dich die Lerninhalte?": "How comprehensible was the learning content for you?",
        "🤩 Sehr verständlich!": "🤩 Very understandable!",
        "😊 Verständlich": "😊 Understandable",
        "😕 Nicht so verständlich": "😕 Not so understandable",
        "😩 Gar nicht verständlich": "😩 Not understandable at all",
        "Wie waren die Aufgaben in den Notfällen 1–8 für dich?": "What were the tasks in emergencies 1-8 like for you?",
        "🤩 Sehr einfach!": "🤩 Very simple!",
        "😊 einfach": "😊 simple",
        "😐 Nicht so einfach": "😐 Not that simple",
        "😕 Schwierig": "😕 Difficult",
        "😩 Sehr schwierig": "😩 Very difficult",
        "Wie beurteilst du die Benutzerfreundlichkeit des eLearnings?": "How would you rate the user-friendliness of eLearning?",
        "🤩 Super einfache Bedienung!": "🤩 Super simple operation!",
        "😊 Einfach zu bedienen": "😊 Easy to operate",
        "😐 Teilweise etwas mühsam": "😐 Sometimes a bit tedious",
        "😩 Richtig mühsame Bedienung": "😩 Really tedious operation",
        "Wie schwierig war für dich die Lernkontrolle am Ende?": "How difficult was the learning check at the end for you?",
        "Warum war die Lernkontrolle für dich einfach?": "Why was the learning check easy for you?",
        "Überspringen": "Skip",
        "Warum war die Lernkontrolle für dich schwierig?": "Why was the learning check difficult for you?",
        "Wie können wir das eLearning verbessern? Was hat dir gefehlt?": "How can we improve eLearning? What did you miss?",
        "Würdest du das eLearning weiterempfehlen?": "Would you recommend eLearning to others?",
        "🤩 Ja klar!": "🤩 Yes, of course!",
        "😊 Ja, wahrscheinlich schon": "😊 Yes, probably",
        "😐 Weiss nicht recht": "😐 Don't really know",
        "😕 Eher nicht": "😕 Rather not",
        "😩 Nein, auf keinen Fall": "😩 No, definitely not",
        "Vielen Dank für deine Antworten!": "Thank you very much for your answers!",
        "Cool hast du dir die Zeit genommen!": "Cool that you took the time!",
        "Fehler beim Laden der Suchergebnisse": "Error loading the search results",
        "Speichern": "Save",
        "Jetzt mit dem eLearning starten": "Start eLearning now",
        "Es wird Zeit, mit dem eLearning zu beginnen! Bitte schliesse es bis zum Kursbeginn am <strong>{courseStart}</strong>, ab, da es die wichtige Grundlage für unseren Kurs bildet. Plane dafür etwa zwei Stunden ein, damit du gut vorbereitet bist und das Beste aus dem Kurs mitnehmen kannst. Wir freuen uns auf dich!": "It's time to start your eLearning! Please complete it by the start of the course at <strong>{courseStart}</strong>, as it forms the important basis for our course. Allow about two hours for this so that you are well prepared and can get the most out of the course. We look forward to seeing you!",
        "Dein Anmeldecode für dein my Sanio Konto lautet: <strong>{code}</strong>": "Your login code for your my Sanio account is: <strong>{code}</strong>",
        "Zu Kurs zugeordnet": "Assigned to course",
        "Teilnehmer:innen {company}": "Participants {company}",
        "{firstname}, eLearning für den Kurs von {company} nicht vergessen!": "{firstname}, don't forget the eLearning for the course from {company}!",
        "Dein my Sanio Login Code": "Your my Sanio login code",
        "Dein my.sanio Verifizierungscode": "Your my.sanio verification code",
        "Ich akzeptiere die {tos} und habe die {privacyPolicy} gelesen und verstanden.": "I accept the {tos} and have read and understood the {privacyPolicy}.",
        "AGBs und Geschäftsbedingen müssen akzeptiert werden.": "The terms and conditions and the privacy policy must be accepted.",
        "Der Kurs ist leider bereits ausgebucht.": "The course is unfortunately already fully booked.",
        "🚫 Wir konnten dich leider nicht am Kurs anmelden.": "🚫 Unfortunately we were unable to register you for the course.",
        "Von:": "From:",
        "An:": "To:",
        "CC:": "CC:",
        "BCC:": "BCC:",
        "Antwort an:": "Reply to:",
        "Anhänge": "Attachments",
        "unbekannter Betreff": "unknown subject",
        "Status": "Status",
        "Kanal": "Channel",
        "Inhalt": "Contents",
        "Persönliche Daten": "Personal data",
        "Zukünftige Kurse": "Future courses",
        "Vergangene Kurse": "Past courses",
        "eLearning": "eLearning",
        "Fortschritt:": "Progress:",
        "Dauer:": "Duration:",
        "Kommunikation": "Communication",
        "Zeit": "Time",
        "Profil": "Profile",
        "Nicht dargestellte Kursdaten": "Course dates not shown",
        "Benutzerbild": "User picture",
        "Meine Kurse": "My courses",
        "Dashboard Instruktor:innen": "Dashboard Instructor:inside",
        "Wenn wir für diese E-Mail Adresse ein Konto gefunden haben, erhältst du in Kürze eine E-Mail mit einem Link zum Einloggen (Bitte überprüfe auch deinen Spam-Ordner).": "If we have found an account for this e-mail address, you will shortly receive an e-mail with a link to log in (please also check your spam folder).",
        "Hier ist dein Link zum Einloggen:": "Here is your link to log in:",
        "Jetzt einloggen": "Log in now",
        "Link zum Einloggen": "Link to log in",
        "Teilnahme-Status erfolgreich aktualisiert.": "Participation status successfully updated.",
        "Team": "Team",
        "Anwesenheit": "Presence",
        "Gemäss Kursleiter:in anwesend": "According to course instructor:in present",
        "Gemäss Kursleiter:in abwesend": "According to course instructor:in absent",
        "🧘 Du hast diese Woche keine Kurse.": "🧘 You don't have any classes this week.",
        "🌴 Du hast keine zukünftigen Kurse.": "🌴 You don't have any future courses.",
        "😌 Du hast keine vergangenen Kurse.": "😌 You don't have any past courses.",
        "Diese Woche": "This week",
        "messages": {
            "communication": {
                "notifications": {
                    "status": {
                        "skipped": "Skipped",
                        "failed": "Failed",
                        "sending": "Sending...",
                        "sent": "Sent"
                    },
                    "channel": {
                        "database": "Database",
                        "twilio": "SMS",
                        "mail": "Email"
                    }
                }
            },
            "feedback": {
                "moods": {
                    "negative": "Negative",
                    "neutral": "Neutral",
                    "positive": "Positive"
                }
            }
        }
    },
    "fr": {
        "Anmelden": "S'inscrire",
        "E-Mail": "E-mail",
        "Passwort": "Mot de passe",
        "Passwort vergessen?": "Mot de passe oublié ?",
        "Eine Woche lang angemeldet bleiben": "Rester connecté pendant une semaine",
        "Willkommen bei": "Bienvenue chez",
        "Passwort zurücksetzen": "Réinitialiser le mot de passe",
        "Sie bekommen von uns einen Link per E-Mail zugesendet, um Ihr Passwort zurückzusetzen. Bitte geben Sie Ihre E-Mail-Adresse ein, die Sie zum Login bei My Sanio verwenden.": "Nous vous enverrons un lien par e-mail pour réinitialiser votre mot de passe. Veuillez saisir l'adresse e-mail que vous utilisez pour vous connecter à My Sanio.",
        "Zurück zur Anmeldung": "Retour à l'inscription",
        "Link anfordern": "Demander un lien",
        "zurück": "retour",
        "Liste exportieren": "Exporter la liste",
        "Anmeldungen": "Inscriptions",
        "Abmeldungen": "Désinscriptions",
        "Vorname": "Prénom",
        "Nachname": "Nom",
        "Kurssprache": "Langue du cours",
        "Beschreibung": "Description",
        "Link ist aktiv": "Le lien est actif",
        "Kurs freigeben": "Valider le cours",
        "Es existieren keine Daten.": "Il n'existe pas de données.",
        "Willkommen": "Bienvenue",
        "Teilnehmende": "Participants",
        "Sortieren nach": "Trier par",
        "Datum": "Date",
        "Gesamte Liste freigeben": "Valider toute la liste",
        "Kursgruppe freigeben": "Valider le groupe de cours",
        "Plätze frei": "Places libres",
        "ausgebucht": "complet",
        "Vielen Dank! Wir haben Ihre Angaben erhalten.": "Merci beaucoup ! Nous avons bien reçu vos informations.",
        "Weitere Person anmelden": "Inscrire une autre personne",
        "An-/Abmeldung": "Inscription/désinscription",
        "Ja, ich nehme teil": "Oui, je participe",
        "Nein, ich bin verhindert": "Non, j'ai un empêchement",
        "Ich akzeptiere die": "J'accepte les",
        "allgemeinen Geschäftsbedingungen": "Conditions générales",
        "Die": "La",
        "Datenschutzerklärung": "Déclaration de confidentialité",
        "habe ich gelesen und verstanden.": "j'ai lu et compris.",
        "Absenden": "Envoyer",
        "Sie müssen die AGB akzeptieren!": "Vous devez accepter les CGV !",
        "Fragen oder Probleme?": "Des questions ou des problèmes ?",
        "Ihre Ansprechperson:": "Votre personne de contact :",
        "Geburtsdatum": "Date de naissance",
        "Geburtsdatum (TT.MM.JJJJ)": "Date de naissance (jj.mm.aaaa)",
        "Hallo": "Salut",
        "Anmeldebestätigung": "Confirmation d'inscription",
        "Erste Hilfe Kurs": "Cours de premiers secours",
        "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos": "Merci beaucoup pour ton inscription au cours de premiers secours. Voici encore une fois les informations les plus importantes",
        "Liebe Grüsse": "Cordialement",
        "Dein Sanio-Team": "Votre équipe Sanio",
        "Copyright": "Droits d'auteur",
        "Umbuchungsbestätigung": "Confirmation de modification de réservation",
        "Terminerinnerung": "Rappel de rendez-vous",
        "Bald ist es soweit und der Erste Hilfe Kurs findet statt. Hier nochmals die wichtigsten Infos:": "Le moment est venu et le cours de premiers secours aura lieu bientôt. Voici à nouveau les informations les plus importantes :",
        "Deutsch": "Allemand",
        "Französisch": "Français",
        "Italienisch": "Italien",
        "Englisch": "Anglais",
        "Spanisch": "Espagnol",
        "Portugiesisch": "Portugais",
        "Kurse": "Cours",
        "Impersonation beenden": "Arrêter l'usurpation",
        "Instructors": "Responsables de cours",
        "Teachers": "Moniteurs de conduite",
        "Kursleiterinnen": "Responsables de cours",
        "The {attribute} must contain at least one letter.": "{attribute} doit être composé d'au moins un caractère.",
        "The {attribute} must contain at least one number.": "{attribute} doit être composé d'au moins un chiffre.",
        "The {attribute} must contain at least one symbol.": "{attribute} doit être composé d'au moins un caractère spécial.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "{attribute} doit être composé d'au moins une lettre majuscule et une lettre minuscule.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Le {attribute} est déjà apparu dans une fuite de données. Veuillez choisir un autre {attribute}.",
        "INSURANCE_FLAT": "Forfait administratif",
        "lessons": "Leçons de conduite",
        "vku": "Cours VKU",
        "insurance": "Assurance",
        "app": "Application théorique",
        "voice_status_start": "Lancement",
        "voice_status_end": "Terminé",
        "voice_status_queue": "File d'attente",
        "voice_status_taken": "en discussion",
        "voice_status_missed": "Manqué",
        "absences": "Absence",
        "exams": "Examens",
        "comments": "Commentaires",
        "employment": "Emploi",
        "course_documents": "Documents de cours",
        "course_manuals": "Instructions de cours",
        "All": "Tous les",
        "Die Datei {fileName} wurde {statusHint} importiert.": "Le fichier {fileName} a été importé {statusHint}.",
        "Adresse": "Adresse",
        "Kursübersicht": "Aperçu des cours",
        "Zurück zum Login": "Retour à la connexion",
        "Passwort bestätigen": "Confirmer le mot de passe",
        "Dashboard": "Tableau de bord",
        "In welchen Kurs soll {participantName} verschoben werden?": "Dans quel cours {participantName} doit-il être déplacé ?",
        "Es gibt keine anderen Kurse.": "Il n'y a pas d'autres cours.",
        "Mehr anzeigen": "Voir plus",
        "{participantName} in Kurs {courseName} verschieben?": "Déplacer {participantName} vers le cours {courseName} ?",
        "Zum Kurs": "Vers le cours",
        "Umbuchungsbestätigung senden": "Envoyer une confirmation de changement de réservation",
        "Zurück": "Retour",
        "Verschieben": "Déplacer",
        "Willkommen {name} 👋": "Bienvenue {name} 👋",
        "Abbrechen": "Annuler",
        "Ähnliche TN": "TN similaires",
        "erfolgreich": "avec succès",
        "Abmelden": "Se désinscrire",
        "certificates.not-yet-known": "Pas encore connu",
        "certificates.sanio": "Confirmation de cours Sanio",
        "certificates.src": "Certificat SRC",
        "Falls Sie Excel für den Import verwenden, achten Sie darauf das Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "Si vous utilisez Excel pour l'importation, veillez à ce que les colonnes telles que la date de naissance soient formatées en tant que texte.",
        "Fehlermeldung": "Message d'erreur",
        "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:": "Les lignes suivantes n'ont pas pu être importées en raison de données non valables (<span class=\"text-red\">marqué en rouge</span>) :",
        "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.": "Les lignes suivantes ont tout de même été importées en raison d'une attribution peu claire.",
        "Handynummer": "Numéro de téléphone portable",
        "Kontaktdaten": "Coordonnées",
        "In Kurs verschieben": "Changer de cours",
        "locations": "Sites",
        "Mitarbeitende": "Collaborateurs",
        "Spalte": "Colonne",
        "students": "Étudiants",
        "Teilnehmerübersicht öffnen": "Ouvrir l'aperçu des participants",
        "teilweise": "en partie",
        "voice_status_out-of-office": "Out-of-Office",
        "voice_status_voicemail": "Message vocal",
        "{amount} Teilnehmende": "{amount} Participants",
        "Zeile": "Ligne",
        "Email": "E-mail",
        "voice_status_initiated": "Bienvenue",
        "errors.4xx.title": "Erreur inattendue",
        "Diese Anmeldemaske wurde deaktiviert.": "Ce masque de connexion a été désactivé.",
        "errors.4xx.head": "Oops...",
        "errors.4xx.hint": "Une erreur s'est produite. Votre demande n'a pas pu être traitée. Veuillez vérifier vos saisies ou réessayer ultérieurement. L'erreur nous a été signalée.",
        "errors.5xx.title": "Erreur de serveur interne",
        "errors.5xx.head": "Ou ou...",
        "errors.5xx.hint": "Une erreur inattendue s'est produite. Retournez à la page d'accueil ou réessayez plus tard. L'erreur nous a été signalée.",
        "errors.401.title": "Accès refusé",
        "errors.401.head": "Oups…",
        "errors.401.hint": "Vous n'êtes pas autorisé(e) à accéder à cette page. Veuillez vous connecter et réessayer.",
        "errors.403.title": "Accès refusé",
        "errors.403.head": "Oups…",
        "errors.403.hint": "L'accès est refusé. Vous n'avez pas l'autorisation de visiter cette page. Veuillez nous contacter si vous pensez qu'il s'agit d'une erreur.",
        "errors.404.title": "Page non trouvée",
        "errors.404.head": "Oops...",
        "errors.404.hint": "La page que vous avez consultée n'existe pas. Revenez en arrière ou cliquez sur le bouton pour revenir à la page d'accueil.",
        "errors.500.title": "Erreur de serveur interne",
        "errors.500.head": "Ou ou...",
        "errors.500.hint": "Une erreur inattendue s'est produite. Retournez à la page d'accueil ou réessayez plus tard. L'erreur nous a été signalée.",
        "errors.503.title": "Mode de maintenance",
        "errors.503.head": "Un instant, s'il vous plaît...",
        "errors.503.hint": "Nous effectuons actuellement des travaux de maintenance afin d'apporter des améliorations et de corriger des bugs pour une meilleure expérience utilisateur. Nous vous invitons à réessayer prochainement. Nous vous remercions de votre compréhension.",
        "errors.503.hint2": "En cas d'urgence, vous pouvez nous contacter par e-mail à <a href=\"mailto:mail@sanio.ch\">mail@sanio.ch</a> ou par téléphone à <a href=\"tel:+41315212409\">031 521 24 09</a>.",
        "errors.504.title": "Délai d'attente",
        "errors.504.head": "Temps mort…",
        "errors.504.hint": "La demande a pris trop de temps. Veuillez réessayer plus tard. L'erreur nous a été signalée.",
        "errors.back-to-start": "Vers la page d'accueil",
        "{attribute} is keine gültige Sprache.": "{attribute} n'est pas une langue valide.",
        "Fehler beim Laden der Kurse": "Erreur lors du chargement des cours",
        "Die aktuellen Infos lauten:": "Voici les nouvelles informations :",
        "Bald ist es soweit und der Kurs «{courseName}» findet statt.": "C'est bientôt l'heure du cours \"{courseName}\".",
        "Hier nochmals die wichtigsten Infos:": "Voici encore une fois les informations les plus importantes :",
        "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉": "Tu t'es inscrit avec succès au cours \"{courseName}\" 🎉",
        "Kurs-ID": "ID du cours",
        "Zum Kalender hinzufügen": "Ajouter au calendrier",
        "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.": "Aucune adresse électronique ou numéro de téléphone portable n'a été indiqué.",
        "Die E-Mail-Adresse ist ungültig.": "L'adresse e-mail n'est pas valide",
        "Du wurdest in den Kurs «{courseName}» umgebucht.": "Tu as été transféré dans le cours \"{courseName}\".",
        "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:": "Si tu as des questions, contacte directement {name} à l'adresse suivante :",
        "oder": "ou",
        "Kursort:": "Lieu du cours :",
        "Kursdaten": "Dates des cours",
        "Die Handynummer ist ungültig.": "Le numéro de téléphone portable n'est pas valide.",
        "Mit dieser E-Mail kannst du dich leider nicht anmelden.": "Vous ne pouvez malheureusement pas vous inscrire avec cet e-mail.",
        "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.": "Vous ne pouvez malheureusement pas vous inscrire avec ce numéro de téléphone.",
        "Der Code ist ungültig.": "Le code n'est pas valide.",
        "Sie müssen die AGBs akzeptieren um fortfahren zu können.": "Vous devez accepter les conditions générales pour continuer.",
        "Bitte geben Sie eine gültige Handynummer ein.": "Veuillez saisir un numéro de téléphone portable valide.",
        "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:": "Merci beaucoup pour ton inscription au cours. Voici encore une fois les informations les plus importantes :",
        "Der Katalog wurde erstellt.": "Le catalogue a été créé.",
        "Der Katalog wurde aktualisiert.": "Le catalogue a été mis à jour.",
        "Der Katalog wurde entfernt.": "Le catalogue a été supprimé.",
        "Das Thema wurde aktualisiert.": "Le sujet a été mis à jour.",
        "Das Thema wurde entfernt.": "Le sujet a été supprimé.",
        "Der Kurs wurde dupliziert 🎉": "Le cours a été dupliqué 🎉",
        "Kurs": "Cours",
        "Text wurde in die Zwischenablage kopiert": "Le texte a été copié dans le presse-papiers",
        "Text konnte nicht in die Zwischenablage kopiert werden": "Le texte n'a pas pu être copié dans le presse-papiers",
        "Das Thema wurde erstellt.": "Le sujet a été créé.",
        "Story wurde erfolgreich kopiert 🎉": "L'histoire a été copiée avec succès 🎉",
        "hier": "ici",
        "Name": "Nom",
        "Eine Variante|{count} Varianten": "Une variante|{count} Variantes",
        "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.": "L'espace eLearning n'est pas disponible pour ce client.",
        "Geschlecht": "Sexe",
        "Männlich": "masculin",
        "Weiblich": "féminin",
        "Divers": "autre",
        "Projektstandort": "Lieu du projet",
        "Wohnkanton": "Canton de résidence",
        "Sanio Logo": "Logo Sanio",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{$unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "Si tu ne souhaites plus recevoir d'e-mails de cette liste, tu peux te désinscrire <a href=\"{$unsubscribeListUrl}\" target=\"_blank\">ici</a>.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{$unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Tu ne veux plus recevoir d'offres de notre part ? Tu peux te désinscrire sur <a href=\"{$unsubscribeUrl}\" target=\"_blank\">ici</a>.",
        "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.": "Cliquez ici pour accéder à nos conditions générales de vente <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\"></a> et à notre déclaration de confidentialité <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\"></a> .",
        "Hallo {name} 👋": "Salut {name} 👋",
        "Hier sind nochmals die wichtigsten Details:": "Voici à nouveau les principaux détails :",
        "Teilnehmer:innen": "Participant(e)s",
        "Letzter Kurs": "Dernier cours",
        "Nächster Kurs": "Prochain cours",
        "Hinzufügen": "Ajouter",
        "Exportieren": "Exporter",
        "Keinem Kurs zugeordnet": "Non affecté à un cours",
        "Suchbegriff": "Recherche de mots-clés",
        "Dein Sanio Team": "Votre équipe Sanio",
        "Alle": "Tous les",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "Si tu ne souhaites plus recevoir d'e-mails de cette liste, tu peux te désinscrire <a href=\"{unsubscribeListUrl}\" target=\"_blank\">ici</a>.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Tu ne veux plus recevoir d'offres de notre part ? Tu peux te désinscrire sur <a href=\"{unsubscribeUrl}\" target=\"_blank\">ici</a>.",
        "Ungruppierte Kurse": "Cours non groupés",
        "Es wurden mehrere Konten für diese Angaben gefunden. Bitte wähle den gewünschten Kunden aus.": "Plusieurs comptes ont été trouvés pour ces données. Veuillez sélectionner le client souhaité.",
        "Anmeldebestätigung erneut zustellen": "Renvoyer la confirmation d'inscription",
        "Am Ende gibt es eine kurze Lernkontrolle. Daher lohnt es sich, die Inhalte aufmerksam durchzuarbeiten!": "À la fin, il y a un petit contrôle d'apprentissage. Il vaut donc la peine d'étudier attentivement les contenus !",
        "Abmelden / entfernen": "Se désinscrire / supprimer",
        "Einladung zum eLearning": "Invitation à l'eLearning",
        "Wir freuen uns, dir mitzuteilen, dass das eLearning für dich nun bereitsteht! Du kannst ab sofort mit dem Lernen beginnen. 🎉": "Nous avons le plaisir de t'annoncer que l'eLearning est désormais disponible pour toi ! Tu peux commencer à apprendre dès maintenant. 🎉",
        "Bis zum <strong>{courseName}</strong> am <strong>{courseStart}</strong> in <strong>{coursePlace}</strong> musst du das eLearning unbedingt abgeschlossen haben. Es dient als Grundlage für den praktischen Kurs.": "D'ici le <strong>{courseName}</strong> du <strong>{courseStart}</strong> à <strong>{coursePlace}</strong>, tu dois absolument avoir terminé l'eLearning. Il sert de base pour le cours pratique.",
        "Das eLearning dauert insgesamt etwa 2 Stunden.": "L'eLearning dure environ 2 heures au total.",
        "Du kannst das eLearning entweder in einem Durchgang oder in mehreren Etappen absolvieren.": "Tu peux suivre l'eLearning soit en une seule fois, soit en plusieurs étapes.",
        "Teilnehmer:in abmelden / aus Kurs entfernen": "Désinscire le/la participant:e",
        "Möchtest du den/die Teilnehmer:in wirklich vom Kurs abmelden / aus dem Kurs entfernen?": "Souhaitez-vous vraiment désinscrire/retirer le/la participant(e) du cours ?",
        "eLearning Einladung verschicken": "Envoyer une invitation eLearning",
        "Willst du wirklich eine eLearning Einladung verschicken?": "Tu veux vraiment envoyer une invitation eLearning ?",
        "Einladung verschicken": "Envoyer une invitation",
        "Willst du wirklich die Anmeldebestätigung erneut zustellen?": "Veux-tu vraiment renvoyer la confirmation d'inscription ?",
        "Bestätigung erneut verschicken": "Renvoyer la confirmation",
        "Teilnehmer:in abmelden": "Désinscire le/la participant(e)",
        "Bemerkungen": "Remarques",
        "Wert fehlt!": "Valeur manquante !",
        "Ihre Ansprechpersonen:": "Personnes à contacter:",
        "{0} Es wurden keine Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.|{1} Es wurde ein:e Teilnehmer:in angelegt und {totalUpdated} aktualisiert.|[2,*] Es wurden {count} Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.": "{0} Aucun(e) participant(e) créé(e) et {totalUpdated} mis(e) à jour |{1} Un(e) participant(e) créé(e) et {totalUpdated} mis(e) à jour |[2,*] {count} participants(es) créés(es) et {totalUpdated} mis(es) à jour.",
        "Archivieren": "Archiver",
        "Zu Kursgruppe hinzufügen": "Ajouter au groupe",
        "Neue Kursgruppe erstellen": "Créer un nouveau groupe",
        "Möchtest du den Kurs wirklich endgültig löschen? Diese Aktion kann nicht rückgängig gemacht werden.": "Tu veux vraiment supprimer définitivement le cours ? Cette action ne peut pas être annulée.",
        "Möchtest du den Kurs wirklich archivieren? Der Kurs ist danach nicht mehr sichtbar, kann aber durch einen Admin wiederhergestellt werden.": "Tu veux vraiment archiver le cours ? Le cours n'est ensuite plus visible, mais peut être restauré par un admin.",
        "Freigabelink löschen": "Supprimer le lien de partage",
        "Möchtest du den Freigabelink wirklich löschen?": "Tu veux vraiment supprimer le lien de partage ?",
        "Endgültig löschen": "Supprimer définitivement",
        "Gruppe löschen": "Supprimer un groupe",
        "Möchtest du diese Gruppe wirklich löschen?": "Tu veux vraiment supprimer ce groupe ?",
        "Kurs aus Gruppe entfernen": "Supprimer le cours du groupe",
        "Kurs duplizieren": "Dupliquer le cours",
        "Kurs archivieren": "Archiver le cours",
        "Kurs löschen": "Supprimer le cours",
        "Der Freigabelink wurde in die Zwischenablage kopiert! 🎉": "Le lien de partage a été copié dans le presse-papier ! 🎉",
        "Der Freigabelink wurde gelöscht! 🎉": "Le lien de partage a été supprimé ! 🎉",
        "Link teilen": "Partager le lien",
        "Gruppe bearbeiten": "Modifier le groupe",
        "Neue:n Teilnehmer:in anmelden": "Inscrire un(e) nouveau/nouvelle participant(e)",
        "nimmt teil": "participe",
        "Ähnliche Teilnehmer:innen": "Participant(e)s similaires",
        "Ein unerwarteter Fehler ist aufgetreten": "Une erreur inattendue s'est produite",
        "Bereits in diesem Kurs vorhanden": "Déjà présent dans ce cours",
        "Es wurden keine ähnlichen Teilnehmer:innen gefunden.": "Aucun participant(e) similaire n'a été trouvé(e).",
        "Fülle das Formular aus und die Suche wird gestartet.": "Remplis le formulaire et la recherche commencera.",
        "Suche ähnliche Teilnehmer:innen...": "Recherche de participant(e)s similaires",
        "eLearning nicht gefunden": "eLearning non trouvé",
        "Wie hat dir das eLearning allgemein gefallen?": "Comment as-tu apprécié l'eLearning en général ?",
        "🤩 Sehr gut!": "🤩 Très bien !",
        "😊 Gut": "😊 Bon",
        "😐 Ok": "😐 Ok",
        "😕 Nicht so gut": "😕 Pas très bien",
        "😩 Schlecht": "😩 Mauvais",
        "Bitte wähle eine Antwort aus.": "Veuillez choisir une réponse.",
        "Wie verständlich waren für dich die Lerninhalte?": "Dans quelle mesure les contenus de l'eLearning étaient-ils compréhensibles pour toi ?",
        "🤩 Sehr verständlich!": "🤩 Très compréhensible !",
        "😊 Verständlich": "😊 Compréhensible",
        "😕 Nicht so verständlich": "😕 Pas très compréhensible",
        "😩 Gar nicht verständlich": "😩 Pas du tout compréhensible",
        "Wie waren die Aufgaben in den Notfällen 1–8 für dich?": "Comment les tâches des urgences 1-8 se sont-elles déroulées pour toi ?",
        "🤩 Sehr einfach!": "🤩 Très simple !",
        "😊 einfach": "😊 simple",
        "😐 Nicht so einfach": "😐 Pas si simple que ça",
        "😕 Schwierig": "😕 Difficile",
        "😩 Sehr schwierig": "😩 Très difficile",
        "Wie beurteilst du die Benutzerfreundlichkeit des eLearnings?": "Comment évalues-tu la facilité d'utilisation de l'eLearning ?",
        "🤩 Super einfache Bedienung!": "🤩 Super facile à utiliser !",
        "😊 Einfach zu bedienen": "😊 Facile à utiliser",
        "😐 Teilweise etwas mühsam": "😐 Parfois un peu laborieux",
        "😩 Richtig mühsame Bedienung": "😩 Utilisation vraiment pénible",
        "Wie schwierig war für dich die Lernkontrolle am Ende?": "Le contrôle des connaissances à la fin a-t-il été difficile pour toi ?",
        "Warum war die Lernkontrolle für dich einfach?": "Pourquoi le contrôle des connaissances était-il facile pour toi ?",
        "Überspringen": "Sauter",
        "Warum war die Lernkontrolle für dich schwierig?": "Pourquoi le contrôle des connaissances était-il difficile pour toi ?",
        "Wie können wir das eLearning verbessern? Was hat dir gefehlt?": "Comment pouvons-nous améliorer l'eLearning ? Qu'est-ce qui t'a manqué ?",
        "Würdest du das eLearning weiterempfehlen?": "Recommanderais-tu l'eLearning ?",
        "🤩 Ja klar!": "🤩 Bien sûr !",
        "😊 Ja, wahrscheinlich schon": "😊 Oui, sans doute",
        "😐 Weiss nicht recht": "😐 Ne sait pas trop",
        "😕 Eher nicht": "😕 Plutôt non",
        "😩 Nein, auf keinen Fall": "😩 Non, en aucun cas",
        "Vielen Dank für deine Antworten!": "Merci beaucoup pour tes réponses !",
        "Cool hast du dir die Zeit genommen!": "C'est cool que tu aies pris le temps !",
        "Fehler beim Laden der Suchergebnisse": "Erreur lors du chargement des résultats de recherche",
        "Speichern": "Sauvegarder",
        "Jetzt mit dem eLearning starten": "Commencer le eLearning maintenant",
        "Es wird Zeit, mit dem eLearning zu beginnen! Bitte schliesse es bis zum Kursbeginn am <strong>{courseStart}</strong>, ab, da es die wichtige Grundlage für unseren Kurs bildet. Plane dafür etwa zwei Stunden ein, damit du gut vorbereitet bist und das Beste aus dem Kurs mitnehmen kannst. Wir freuen uns auf dich!": "Il est temps de commencer l'eLearning ! Merci de le terminer avant le début du cours le <strong>{courseStart} h</strong>, car il constitue une base importante pour notre cours. Prévois environ deux heures pour cela, afin d'être bien préparé et de pouvoir tirer le meilleur parti du cours. Nous nous réjouissons de t'accueillir !",
        "Dein Anmeldecode für dein my Sanio Konto lautet: <strong>{code}</strong>": "Ton code d'inscription pour ton compte my Sanio est : <strong>{code}</strong>",
        "Zu Kurs zugeordnet": "Affecté au cours",
        "Teilnehmer:innen {company}": "Participants {company}",
        "{firstname}, eLearning für den Kurs von {company} nicht vergessen!": "{firstname}, n’oublie pas l'eLearning pour le cours de {company} !",
        "Dein my Sanio Login Code": "Ton code de connexion my Sanio",
        "Dein my.sanio Verifizierungscode": "Ton code de vérification my.sanio",
        "Ich akzeptiere die {tos} und habe die {privacyPolicy} gelesen und verstanden.": "J’accepte les {tos} et j’ai lu et compris la {privacyPolicy}.",
        "AGBs und Geschäftsbedingen müssen akzeptiert werden.": "Les CGV et la politique de confidentialité doivent être acceptées.",
        "Der Kurs ist leider bereits ausgebucht.": "Malheureusement, le cours est déjà complet.",
        "🚫 Wir konnten dich leider nicht am Kurs anmelden.": "🚫 Malheureusement, nous n’avons pas pu t’inscrire au cours.",
        "Profil": "Profil",
        "Zukünftige Kurse": "Cours futurs",
        "Vergangene Kurse": "Cours passés",
        "eLearning": "eLearning",
        "Fortschritt:": "Progrès :",
        "Dauer:": "Durée :",
        "Inhalt": "Contenu",
        "Von:": "De :",
        "An:": "À :",
        "CC:": "CC :",
        "BCC:": "BCC :",
        "Persönliche Daten": "Données personnelles",
        "Kommunikation": "Communication",
        "unbekannter Betreff": "Objet inconnu",
        "Zeit": "Temps",
        "Status": "Statut",
        "Kanal": "Canal",
        "Antwort an:": "Réponse à :",
        "Anhänge": "Annexes",
        "Nicht dargestellte Kursdaten": "Dates de cours non représentées",
        "Benutzerbild": "Image d'utilisateur",
        "Meine Kurse": "Mes cours",
        "Dashboard Instruktor:innen": "Instructeur(trice) Dashboard",
        "Wenn wir für diese E-Mail Adresse ein Konto gefunden haben, erhältst du in Kürze eine E-Mail mit einem Link zum Einloggen (Bitte überprüfe auch deinen Spam-Ordner).": "Si nous avons trouvé un compte pour cette adresse e-mail, tu recevras sous peu un e-mail avec un lien pour te connecter (vérifie aussi ton dossier spam).",
        "Hier ist dein Link zum Einloggen:": "Voici ton lien pour te connecter :",
        "Jetzt einloggen": "Connectez-vous maintenant",
        "Link zum Einloggen": "Lien pour se connecter",
        "Teilnahme-Status erfolgreich aktualisiert.": "Statut de participation mis à jour avec succès.",
        "Team": "Équipe",
        "Anwesenheit": "Présence",
        "😌 Du hast keine vergangenen Kurse.": "😌 Tu n'as pas de cours passés.",
        "Gemäss Kursleiter:in anwesend": "Selon le responsable du cours:présent",
        "Gemäss Kursleiter:in abwesend": "Selon le responsable du cours:in absent",
        "🧘 Du hast diese Woche keine Kurse.": "🧘 Tu n'as pas de cours cette semaine.",
        "🌴 Du hast keine zukünftigen Kurse.": "🌴 Tu n'as pas de cours à venir.",
        "Diese Woche": "Cette semaine",
        "messages": {
            "communication": {
                "notifications": {
                    "status": {
                        "sending": "En cours d’envoi…",
                        "skipped": "Ignoré",
                        "sent": "Envoyé",
                        "failed": "Échoué"
                    },
                    "channel": {
                        "database": "Base de données",
                        "twilio": "SMS",
                        "mail": "E-mail"
                    }
                }
            },
            "feedback": {
                "moods": {
                    "negative": "Négative",
                    "neutral": "Neutre",
                    "positive": "Positif"
                }
            }
        }
    },
    "it": {
        "Anmelden": "Iscrizione",
        "E-Mail": "e-mail",
        "Passwort": "Password",
        "Passwort vergessen?": "Avete dimenticato la password?",
        "Eine Woche lang angemeldet bleiben": "Rimanete connessi per una settimana",
        "Willkommen bei": "Benvenuti a",
        "Passwort zurücksetzen": "Reimpostare la password",
        "Sie bekommen von uns einen Link per E-Mail zugesendet, um Ihr Passwort zurückzusetzen. Bitte geben Sie Ihre E-Mail-Adresse ein, die Sie zum Login bei My Sanio verwenden.": "Riceverete un link via e-mail per reimpostare la password. Inserire l'indirizzo e-mail utilizzato per accedere a My Sanio.",
        "Zurück zur Anmeldung": "Torna all'accesso",
        "Link anfordern": "Link per la richiesta",
        "zurück": "indietro",
        "Liste exportieren": "Elenco di esportazione",
        "Anmeldungen": "Registrazioni",
        "Abmeldungen": "Cancellazioni",
        "Vorname": "Nome",
        "Nachname": "Cognome",
        "Kurssprache": "Lingua del corso",
        "Beschreibung": "Descrizione",
        "Link ist aktiv": "Il collegamento è attivo",
        "Kurs freigeben": "Condividi il corso",
        "Es existieren keine Daten": "Non ci sono dati esistenti.",
        "Willkommen": "Benvenuti",
        "Teilnehmende": "Partecipanti",
        "Sortieren nach": "Ordina per",
        "Datum": "Data",
        "Adresse": "Indirizzo",
        "Gesamte Liste freigeben": "Condividi l'intero elenco",
        "Kursgruppe freigeben": "Condividere il gruppo di corsi",
        "Plätze frei": "Slot rimanenti",
        "ausgebucht": "al completo",
        "Vielen Dank! Wir haben Ihre Angaben erhalten": "Grazie mille! Abbiamo ricevuto i vostri dati.",
        "Weitere Person anmelden": "Registrazione di una persona aggiuntiva",
        "An-/Abmeldung": "Registrazione/annullamento",
        "Ja, ich nehme teil": "Sì, partecipo",
        "Nein, ich bin verhindert": "No, non posso partecipare",
        "Ich akzeptiere die": "Accetto il",
        "allgemeinen Geschäftsbedingungen": "Termini e condizioni",
        "Die": "Il",
        "Datenschutzerklärung": "Informativa sulla privacy",
        "habe ich gelesen und verstanden": "Ho letto e compreso.",
        "Absenden": "Inviare",
        "Sie müssen die AGB akzeptieren!": "Dovete accettare le condizioni!",
        "Fragen oder Probleme?": "Domande o problemi?",
        "Ihre Ansprechperson:": "Il vostro interlocutore:",
        "Geburtsdatum": "Data di nascita",
        "Hallo": "Ciao",
        "Anmeldebestätigung": "Conferma dell'iscrizione",
        "Erste Hilfe Kurs": "Corso di Primo Soccorso",
        "Vielen Dank für deine Anmeldung zum Erste Hilfe Kurs. Hier nochmals die wichtigsten Infos": "Grazie per esserti iscritto al corso di Primo Soccorso. Ecco ancora una volta le informazioni più importanti",
        "Liebe Grüsse": "Cordiali saluti",
        "Dein Sanio-Team": "Il tuo Team Sanio",
        "Copyright": "Diritti d'autore",
        "Umbuchungsbestätigung": "Conferma di modifica della prenotazione",
        "Terminerinnerung": "Promemoria appuntamento",
        "Bald ist es soweit und der Erste Hilfe Kurs findet statt. Hier nochmals die wichtigsten Infos:": "Il momento è arrivato e il corso di primo soccorso si terrà presto. Ecco ancora una volta le informazioni più importanti:",
        "Deutsch": "Tedesco",
        "Französisch": "Francese",
        "Italienisch": "Italiano",
        "Englisch": "Inglese",
        "Spanisch": "Spagnolo",
        "Portugiesisch": "Portoghese",
        "Kurse": "Corsi",
        "Impersonation beenden": "Termina l'impersonificazione",
        "course_manuals": "Istruzioni per il corso",
        "employment": "Occupazione",
        "habe ich gelesen und verstanden.": "Ho letto e compreso.",
        "Instructors": "Istruttori",
        "insurance": "Assicurazione",
        "INSURANCE_FLAT": "Tassa di amministrazione",
        "Kontaktdaten": "Dettagli di contatto",
        "In Kurs verschieben": "Cambiamento di rotta",
        "Kursleiterinnen": "Istruttori",
        "lessons": "Lezioni di guida",
        "locations": "Luoghi",
        "Mitarbeitende": "I dipendenti",
        "Kursübersicht": "Panoramica del corso",
        "Zurück zum Login": "Torna al login",
        "Passwort bestätigen": "Confermare la password",
        "Dashboard": "Cruscotto",
        "In welchen Kurs soll {participantName} verschoben werden?": "In quale corso dovrebbe essere spostato {participantName}?",
        "Es gibt keine anderen Kurse.": "Non ci sono altri corsi.",
        "Mehr anzeigen": "Mostra di più",
        "{participantName} in Kurs {courseName} verschieben?": "Spostare {participantName} al corso {courseName}?",
        "Zum Kurs": "Al corso",
        "Umbuchungsbestätigung senden": "Inviare la conferma della prenotazione",
        "Zurück": "Indietro",
        "Verschieben": "Muoversi",
        "Willkommen {name} 👋": "Benvenuto {name} 👋",
        "Die Datei {fileName} wurde {statusHint} importiert.": "Il file {fileName} è stato importato {statusHint}.",
        "erfolgreich": "Successo",
        "exams": "Esami",
        "Falls Sie Excel für den Import verwenden, achten Sie darauf das Spalten wie z.B. das Geburtsdatum als Text formatiert sind.": "Se si utilizza Excel per l'importazione, assicurarsi che colonne come la data di nascita siano formattate come testo.",
        "Fehlermeldung": "Messaggio di errore",
        "Folgende Zeilen konnten aufgrund ungültiger (<span class=\"text-red\">rot markiert</span>) Daten nicht importiert werden:": "<span class=\"text-red\"></span>Le righe seguenti non possono essere importate a causa di dati non validi (contrassegnati in rosso):",
        "Folgende Zeilen wurden aufgrund unklarer Zuordnung trotzdem importiert.": "Le righe seguenti sono state comunque importate a causa di un'assegnazione non chiara.",
        "Handynummer": "Numero di cellulare",
        "app": "Applicazione teorica",
        "absences": "Assenze",
        "comments": "Commenti",
        "course_documents": "Documenti del corso",
        "All": "Tutti",
        "Spalte": "Colonna",
        "students": "Alunni",
        "Teachers": "Istruttori di guida",
        "Teilnehmerübersicht öffnen": "Panoramica dei partecipanti aperti",
        "teilweise": "in parte",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "L'{attribute} è già apparso in una fuga di dati. Selezionare un altro {attribute}.",
        "The {attribute} must contain at least one letter.": "{attribute} deve essere composto da almeno un carattere.",
        "The {attribute} must contain at least one number.": "{attribute} deve essere composto da almeno un numero.",
        "The {attribute} must contain at least one symbol.": "{attribute} deve essere composto da almeno un carattere speciale.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "{attribute} deve essere composto da almeno una lettera maiuscola e una minuscola.",
        "Vielen Dank! Wir haben Ihre Angaben erhalten.": "Grazie mille! Abbiamo ricevuto i vostri dati.",
        "vku": "Corsi VKU",
        "voice_status_end": "Finito",
        "voice_status_missed": "Mancato",
        "voice_status_out-of-office": "Fuori ufficio",
        "voice_status_queue": "Coda",
        "voice_status_start": "Inizio",
        "voice_status_taken": "in dialogo",
        "voice_status_voicemail": "Messaggio vocale",
        "{amount} Teilnehmende": "{amount} partecipanti",
        "Zeile": "Linea",
        "Ähnliche TN": "TN simile",
        "Es existieren keine Daten.": "Non esistono dati.",
        "Abbrechen": "Annullamento",
        "Abmelden": "Disconnettersi",
        "certificates.not-yet-known": "Non ancora noto",
        "certificates.sanio": "Conferma del corso Sanio",
        "certificates.src": "Certificato SRC",
        "Email": "e-mail",
        "voice_status_initiated": "Benvenuti",
        "Diese Anmeldemaske wurde deaktiviert.": "Questa schermata di accesso è stata disattivata.",
        "errors.4xx.title": "Errore imprevisto",
        "errors.4xx.head": "Ops...",
        "errors.4xx.hint": "Si è verificato un errore. Non è stato possibile elaborare la richiesta. Si prega di controllare i dati inseriti o di riprovare più tardi. L'errore è stato segnalato.",
        "errors.5xx.title": "Errore interno del server",
        "errors.5xx.head": "Ou ou...",
        "errors.5xx.hint": "Si è verificato un errore imprevisto. Tornare alla pagina iniziale o riprovare più tardi. L'errore è stato segnalato.",
        "errors.401.title": "Accesso negato",
        "errors.401.head": "Ops…",
        "errors.401.hint": "Non sei autorizzato ad accedere a questa pagina. Effettuare il login e riprovare.",
        "errors.403.title": "Accesso negato",
        "errors.403.head": "Ops…",
        "errors.403.hint": "Accesso negato. Non siete autorizzati a visitare questa pagina. Se ritenete che si tratti di un errore, contattateci.",
        "errors.404.title": "Pagina non trovata",
        "errors.404.head": "Ops...",
        "errors.404.hint": "La pagina richiamata non esiste. Tornare indietro o fare clic sul pulsante per tornare alla pagina iniziale.",
        "errors.500.title": "Errore interno del server",
        "errors.500.head": "Ou ou...",
        "errors.504.head": "Timeout…",
        "errors.504.hint": "La richiesta è durata troppo a lungo. Si prega di riprovare più tardi. L'errore è stato segnalato.",
        "errors.back-to-start": "Alla pagina iniziale",
        "errors.500.hint": "Si è verificato un errore imprevisto. Tornare alla pagina iniziale o riprovare più tardi. L'errore è stato segnalato.",
        "errors.503.title": "Modalità di manutenzione",
        "errors.503.head": "Un momento per favore...",
        "errors.503.hint": "Stiamo effettuando una manutenzione per apportare miglioramenti e correzioni di bug per migliorare l'esperienza dell'utente. Si prega di riprovare a breve. Grazie per la vostra comprensione.",
        "errors.503.hint2": "<a href=\"mailto:mail@sanio.ch\"></a> <a href=\"tel:+41315212409\"></a>In caso di urgenza, potete contattarci via e-mail all'indirizzo mail@sanio.ch o telefonicamente al numero 031 521 24 09 .",
        "errors.504.title": "Timeout",
        "{attribute} is keine gültige Sprache.": "{attribute} non è un linguaggio valido.",
        "Fehler beim Laden der Kurse": "Errore nel caricamento dei corsi",
        "Du wurdest in den Kurs «{courseName}» umgebucht.": "La tua iscrizione al \"{courseName}\" è stata modificata.",
        "Die aktuellen Infos lauten:": "Ecco le nuove informazioni:",
        "Wenn du Fragen hast, melde dich bitte direkt bei {name} unter:": "Per qualsiasi domanda puoi contattare {name}:",
        "oder": "o",
        "Kursort:": "Sede del corso:",
        "Kursdaten": "Date del corso",
        "Bald ist es soweit und der Kurs «{courseName}» findet statt.": "Presto parteciperai al \"{courseName}\".",
        "Kurs-ID": "ID corso",
        "Hier nochmals die wichtigsten Infos:": "Ecco di nuovo le informazioni più importanti:",
        "Du wurdest erfolgreich am Kurs «{courseName}» angemeldet 🎉": "L'iscrizione al corso \"{courseName}\" è stata effettuata con successo 🎉",
        "Zum Kalender hinzufügen": "Aggiungi al calendario",
        "Es wurde keine E-Mail-Adresse oder Handynummer angegeben.": "Non è stato fornito alcun indirizzo e-mail o numero di cellulare.",
        "Die E-Mail-Adresse ist ungültig.": "L'indirizzo e-mail non è valido",
        "Die Handynummer ist ungültig.": "Il numero di cellulare non è valido.",
        "Mit dieser E-Mail kannst du dich leider nicht anmelden.": "Purtroppo non è possibile registrarsi con questa e-mail.",
        "Mit dieser Telefonnummer kannst du dich leider nicht anmelden.": "Purtroppo non è possibile registrarsi con questo numero di telefono.",
        "Der Code ist ungültig.": "Il codice non è valido.",
        "Sie müssen die AGBs akzeptieren um fortfahren zu können.": "Devi accettare i termini e le condizioni per continuare.",
        "Bitte geben Sie eine gültige Handynummer ein.": "Inserire un numero di cellulare valido.",
        "Vielen Dank für deine Anmeldung zum Kurs. Hier nochmals die wichtigsten Infos:": "Grazie per esserti iscritto al corso di Primo Soccorso. Ecco ancora una volta le informazioni più importanti:",
        "Der Kurs wurde dupliziert 🎉": "Il corso è stato duplicato 🎉",
        "Kurs": "Corso",
        "Text wurde in die Zwischenablage kopiert": "Il testo è stato copiato negli appunti",
        "Text konnte nicht in die Zwischenablage kopiert werden": "Non è stato possibile copiare il testo negli appunti",
        "Der Katalog wurde erstellt.": "È stato creato il catalogo.",
        "Der Katalog wurde aktualisiert.": "Il catalogo è stato aggiornato.",
        "Der Katalog wurde entfernt.": "Il catalogo è stato rimosso.",
        "Das Thema wurde erstellt.": "L'argomento è stato creato.",
        "Das Thema wurde aktualisiert.": "L'argomento è stato aggiornato.",
        "Das Thema wurde entfernt.": "L'argomento è stato rimosso.",
        "Story wurde erfolgreich kopiert 🎉": "La storia è stata copiata con successo 🎉",
        "Geschlecht": "Genere",
        "Eine Variante|{count} Varianten": "Una variante|{count} Varianti",
        "Für diesen Kunden steht der eLearning Bereich nicht zur Verfügung.": "L'area eLearning non è disponibile per questo cliente.",
        "Männlich": "maschile",
        "Weiblich": "femminile",
        "Divers": "altra definizione di genere",
        "Projektstandort": "Sede del progetto",
        "Wohnkanton": "Cantone di residenza",
        "Sanio Logo": "Logo Sanio",
        "hier": "qui",
        "Hallo {name} 👋": "Ciao {name} 👋",
        "Hier sind nochmals die wichtigsten Details:": "Ti inviamo nuovamente i dettagli più importanti:",
        "Teilnehmer:innen": "Partecipanti",
        "Name": "Nome",
        "Letzter Kurs": "Ultimo corso",
        "Nächster Kurs": "Il prossimo corso",
        "Hinzufügen": "Aggiungi",
        "Exportieren": "Esportazione",
        "Keinem Kurs zugeordnet": "Non assegnato a un corso",
        "Suchbegriff": "Termine di ricerca",
        "Dein Sanio Team": "Il tuo Team Sanio",
        "Alle": "Tutti",
        "Hier geht es zu unseren <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a> und zu unserer <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">Datenschutzerklärung</a>.": "Qui trovi i nostri <a href=\"https://www.sanio.ch/allgemeine-geschaeftsbedingungen/\" target=\"_blank\">termini e condizioni generali</a> e la nostra <a href=\"https://www.sanio.ch/datenschutz/\" target=\"_blank\">informativa sulla privacy</a>.",
        "Falls du keine weiteren E-Mails von dieser Liste erhalten möchtest, kannst du dich <a href=\"{unsubscribeListUrl}\" target=\"_blank\">hier</a> abmelden.": "Se non desideri più ricevere e-mail da questa lista, puoi annullare l'iscrizione <a href=\"{unsubscribeListUrl}\" target=\"_blank\">qui</a>.",
        "Möchtest du gar keine Angebote mehr von uns erhalten? Dann kannst du dich <a href=\"{unsubscribeUrl}\" target=\"_blank\">hier</a> abmelden.": "Non vuoi più ricevere offerte da parte nostra? Puoi annullare l'iscrizione <a href=\"{unsubscribeUrl}\" target=\"_blank\">qui</a>.",
        "Ungruppierte Kurse": "Corsi non raggruppati",
        "Es wurden mehrere Konten für diese Angaben gefunden. Bitte wähle den gewünschten Kunden aus.": "Sono stati trovati diversi account per queste informazioni. Selezionare il cliente desiderato.",
        "Teilnehmer:in abmelden / aus Kurs entfernen": "Partecipante:in logout / rimozione dal corso",
        "eLearning Einladung verschicken": "Inviare un invito eLearning",
        "Anmeldebestätigung erneut zustellen": "Inviare nuovamente la conferma della registrazione",
        "Einladung zum eLearning": "Invito all'eLearning",
        "Wir freuen uns, dir mitzuteilen, dass das eLearning für dich nun bereitsteht! Du kannst ab sofort mit dem Lernen beginnen. 🎉": "Siamo lieti di informarti che l’eLearning è ora disponibile! Puoi finalmente iniziare ad imparare! 🎉",
        "Bis zum <strong>{courseName}</strong> am <strong>{courseStart}</strong> in <strong>{coursePlace}</strong> musst du das eLearning unbedingt abgeschlossen haben. Es dient als Grundlage für den praktischen Kurs.": "È necessario aver completato l’eLearning prima di <strong>{courseStart}</strong> per partecipare al <strong>{courseName}</strong> che si svolgerà a <strong>{coursePlace}</strong>. Questa è la parte teorica necessaria per poter partecipare al corso pratico.",
        "Das eLearning dauert insgesamt etwa 2 Stunden.": "L'eLearning dura circa 2 ore in totale.",
        "Du kannst das eLearning entweder in einem Durchgang oder in mehreren Etappen absolvieren.": "È possibile dompletare l’eLearning in un’unica sessione o in più sessioni.",
        "Am Ende gibt es eine kurze Lernkontrolle. Daher lohnt es sich, die Inhalte aufmerksam durchzuarbeiten!": "Alla fine ci sarà une breve verifica rispetto ai contenuti appresi. Vale quindi la pena approfondire attentamente i contenuti!",
        "Möchtest du den/die Teilnehmer:in wirklich vom Kurs abmelden / aus dem Kurs entfernen?": "Vuoi davvero cancellare la registrazione/eliminare il partecipante dal corso?",
        "Abmelden / entfernen": "Disconnessione / rimozione",
        "Willst du wirklich eine eLearning Einladung verschicken?": "Volete davvero inviare un invito eLearning?",
        "Einladung verschicken": "Inviare l'invito",
        "Willst du wirklich die Anmeldebestätigung erneut zustellen?": "Vuoi davvero inviare nuovamente la conferma di registrazione?",
        "Bestätigung erneut verschicken": "Inviare nuovamente la conferma",
        "Teilnehmer:in abmelden": "Annullare l'iscrizione del partecipante",
        "Bemerkungen": "Osservazioni",
        "Wert fehlt!": "Dato mancante!",
        "Ihre Ansprechpersonen:": "Le vostre persone di riferimento:",
        "{0} Es wurden keine Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.|{1} Es wurde ein:e Teilnehmer:in angelegt und {totalUpdated} aktualisiert.|[2,*] Es wurden {count} Teilnehmer:innen angelegt und {totalUpdated} aktualisiert.": "{0} Non è stato creato alcun partecipante e {totalUpdated} è stato aggiornato.|{1} È stato creato un partecipante e {totalUpdated} è stato aggiornato.|[2,*] Sono stati creati {count} partecipanti e {totalUpdated} è stato aggiornato.",
        "Zu Kursgruppe hinzufügen": "Aggiungi al corso/gruppo",
        "Wie schwierig war für dich die Lernkontrolle am Ende?": "Quanto è stato difficile per te il test finale di apprendimento?",
        "Überspringen": "Saltare",
        "Neue Kursgruppe erstellen": "Crea un nuovo gruppo",
        "Möchtest du den Kurs wirklich endgültig löschen? Diese Aktion kann nicht rückgängig gemacht werden.": "Vuoi davvero cancellare il corso in modo permanente? Questa azione non può essere annullata.",
        "Möchtest du den Kurs wirklich archivieren? Der Kurs ist danach nicht mehr sichtbar, kann aber durch einen Admin wiederhergestellt werden.": "Vuoi davvero archiviare il corso? Il corso non sarà più visibile, ma potrà essere ripristinato da un amministratore.",
        "Freigabelink löschen": "Eliminare il link di condivisione",
        "Möchtest du den Freigabelink wirklich löschen?": "Vuoi davvero eliminare il link di condivisione?",
        "Archivieren": "Archiviare",
        "Endgültig löschen": "Cancellare definitivamente",
        "Gruppe löschen": "Cancellare il gruppo",
        "Möchtest du diese Gruppe wirklich löschen?": "Vuoi davvero cancellare questo gruppo?",
        "Kurs aus Gruppe entfernen": "Rimuovere il corso dal gruppo",
        "Kurs duplizieren": "Duplicare il corso",
        "Kurs archivieren": "Archiviare il corso",
        "Kurs löschen": "Cancellare il corso",
        "Der Freigabelink wurde in die Zwischenablage kopiert! 🎉": "Il link di condivisione è stato copiato negli appunti! 🎉",
        "Der Freigabelink wurde gelöscht! 🎉": "Il link di condivisione è stato cancellato! 🎉",
        "Link teilen": "Condividi il link",
        "Gruppe bearbeiten": "Modifica il gruppo",
        "Neue:n Teilnehmer:in anmelden": "Registra nuovi partecipanti",
        "nimmt teil": "partecipa",
        "Ähnliche Teilnehmer:innen": "Partecipanti simili",
        "Ein unerwarteter Fehler ist aufgetreten": "Si è verificato un errore imprevisto",
        "Bereits in diesem Kurs vorhanden": "Già presente in questo corso",
        "Es wurden keine ähnlichen Teilnehmer:innen gefunden.": "Non sono stati trovati partecipanti simili.",
        "Fülle das Formular aus und die Suche wird gestartet.": "Compila il modulo e la ricerca avrà inizio.",
        "Suche ähnliche Teilnehmer:innen...": "Cerca partecipanti simili...",
        "eLearning nicht gefunden": "eLearning non trovato",
        "Wie hat dir das eLearning allgemein gefallen?": "Cosa ne pensi dell'eLearning in generale?",
        "🤩 Sehr gut!": "🤩 Molto buono!",
        "😊 Gut": "😊 Buono",
        "😐 Ok": "😐 Ok",
        "😕 Nicht so gut": "😕 Non molto buono",
        "😩 Schlecht": "😩 Male",
        "Bitte wähle eine Antwort aus.": "Seleziona una risposta.",
        "Wie verständlich waren für dich die Lerninhalte?": "Quanto è stato comprensibile per te il contenuto dell‘eLearning?",
        "🤩 Sehr verständlich!": "🤩 Molto comprensibile!",
        "😊 Verständlich": "😊 Comprensibile",
        "😕 Nicht so verständlich": "😕 Non così comprensibile",
        "😩 Gar nicht verständlich": "😩 Non è affatto comprensibile",
        "Wie waren die Aufgaben in den Notfällen 1–8 für dich?": "Come sono stati per te i compiti nelle emergenze 1-8?",
        "🤩 Sehr einfach!": "🤩 Molto semplici!",
        "😊 einfach": "😊 semplici",
        "😐 Nicht so einfach": "😐 Non così facili",
        "😕 Schwierig": "😕 Difficili",
        "😩 Sehr schwierig": "😩 Molto difficili",
        "Wie beurteilst du die Benutzerfreundlichkeit des eLearnings?": "Come valuti la facilità d'uso dell'eLearning?",
        "🤩 Super einfache Bedienung!": "🤩 Funzionamento semplicissimo!",
        "😊 Einfach zu bedienen": "😊 Facile da usare",
        "😐 Teilweise etwas mühsam": "😐 A volte un po' strano",
        "😩 Richtig mühsame Bedienung": "😩 Operazione davvero strana",
        "Warum war die Lernkontrolle für dich einfach?": "Perché il test di apprendimento è stato per te facile?",
        "Warum war die Lernkontrolle für dich schwierig?": "Perché il test di apprendimento è stato per te difficile?",
        "Wie können wir das eLearning verbessern? Was hat dir gefehlt?": "Come possiamo migliorare l'eLearning? Cosa ti è mancato?",
        "Würdest du das eLearning weiterempfehlen?": "Consiglieresti l'eLearning ad altri?",
        "🤩 Ja klar!": "🤩 Sì, certo!",
        "😊 Ja, wahrscheinlich schon": "😊 Sì, probabilmente",
        "😐 Weiss nicht recht": "😐 Non lo so",
        "😕 Eher nicht": "😕 Piuttosto no",
        "😩 Nein, auf keinen Fall": "😩 No, assolutamente no",
        "Vielen Dank für deine Antworten!": "Grazie mille per le tue risposte!",
        "Cool hast du dir die Zeit genommen!": "Bello che ti sei preso il tempo necessario!",
        "Fehler beim Laden der Suchergebnisse": "Errore nel caricamento dei risultati della ricerca",
        "Speichern": "Salva",
        "Jetzt mit dem eLearning starten": "Inizia adesso con l’eLearning",
        "Es wird Zeit, mit dem eLearning zu beginnen! Bitte schliesse es bis zum Kursbeginn am <strong>{courseStart}</strong>, ab, da es die wichtige Grundlage für unseren Kurs bildet. Plane dafür etwa zwei Stunden ein, damit du gut vorbereitet bist und das Beste aus dem Kurs mitnehmen kannst. Wir freuen uns auf dich!": "È ora di iniziare l'eLearning! Ti preghiamo di completarlo entro <strong>{courseStart}</strong>, poiché costituisce una base importante per il nostro corso pratico. Dedica circa due ore per completarlo, in modo da essere ben preparato e trarre il massimo dal corso. Non vediamo l'ora di vederti!",
        "Dein Anmeldecode für dein my Sanio Konto lautet: <strong>{code}</strong>": "Il codice di accesso al tuo account my Sanio è: <strong>{code}</strong>",
        "Zu Kurs zugeordnet": "Assegnato al corso",
        "Teilnehmer:innen {company}": "Partecipanti {company}",
        "Dein my Sanio Login Code": "Il vostro codice di accesso my Sanio",
        "{firstname}, eLearning für den Kurs von {company} nicht vergessen!": "{firstname}, non dimenticare l'eLearning per il corso di {company}!",
        "Dein my.sanio Verifizierungscode": "Il tuo codice di verifica my.sanio",
        "AGBs und Geschäftsbedingen müssen akzeptiert werden.": "Le CGC e l’informativa sulla privacy devono essere accettate.",
        "Der Kurs ist leider bereits ausgebucht.": "Purtroppo, il corso è già al completo.",
        "Ich akzeptiere die {tos} und habe die {privacyPolicy} gelesen und verstanden.": "Accetto i {tos} e ho letto e compreso la {privacyPolicy}.",
        "🚫 Wir konnten dich leider nicht am Kurs anmelden.": "🚫 Purtroppo, non siamo riusciti a iscriverti al corso.",
        "Persönliche Daten": "Dati personali",
        "Zukünftige Kurse": "Corsi futuri",
        "Vergangene Kurse": "Corsi passati",
        "eLearning": "eLearning",
        "Fortschritt:": "Progressi:",
        "Dauer:": "Durata:",
        "Kommunikation": "Comunicazione",
        "Kanal": "Canale",
        "unbekannter Betreff": "Soggetto sconosciuto",
        "Zeit": "Tempo",
        "Status": "Stato",
        "Inhalt": "Contenuti",
        "Von:": "Da:",
        "An:": "A:",
        "CC:": "CC:",
        "BCC:": "BCC:",
        "Antwort an:": "Risposta a:",
        "Anhänge": "Allegati",
        "Profil": "Profilo",
        "Nicht dargestellte Kursdaten": "Date dei corsi non indicate",
        "Benutzerbild": "Immagine utente",
        "Meine Kurse": "I miei corsi",
        "Dashboard Instruktor:innen": "Istruttore del cruscotto",
        "Hier ist dein Link zum Einloggen:": "Ecco il link per accedere:",
        "Wenn wir für diese E-Mail Adresse ein Konto gefunden haben, erhältst du in Kürze eine E-Mail mit einem Link zum Einloggen (Bitte überprüfe auch deinen Spam-Ordner).": "Se abbiamo trovato un account per questo indirizzo e-mail, riceverete a breve un'e-mail con un link per accedere (controllate anche la cartella spam).",
        "Jetzt einloggen": "Accedi ora",
        "Link zum Einloggen": "Link per il login",
        "Teilnahme-Status erfolgreich aktualisiert.": "Lo stato di partecipazione è stato aggiornato con successo.",
        "Team": "Squadra",
        "Anwesenheit": "Presenza",
        "Gemäss Kursleiter:in anwesend": "Secondo l'istruttore del corso: nel presente",
        "Gemäss Kursleiter:in abwesend": "Secondo l'istruttore del corso: in assenza",
        "🧘 Du hast diese Woche keine Kurse.": "🧘 Non hai lezioni questa settimana.",
        "🌴 Du hast keine zukünftigen Kurse.": "🌴 Non hai corsi futuri.",
        "😌 Du hast keine vergangenen Kurse.": "😌 Non hai corsi passati.",
        "Diese Woche": "Questa settimana",
        "messages": {
            "feedback": {
                "moods": {
                    "positive": "Positivo",
                    "negative": "Negativo",
                    "neutral": "Neutro"
                }
            },
            "communication": {
                "notifications": {
                    "channel": {
                        "database": "Database",
                        "twilio": "SMS",
                        "mail": "Email"
                    },
                    "status": {
                        "sent": "Inviato",
                        "sending": "In fase di invio…",
                        "skipped": "Saltato",
                        "failed": "Fallito"
                    }
                }
            }
        }
    }
}
